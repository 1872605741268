import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProfileById } from "../../../../api/apis";
import ErrorIllustrative from "../../../../components/IllustrativeComponents/ErrorIllustrative";
import SocialNetworksComponent from "../../../../components/SocialNetworksComponent";
import { errorParser, prepareTranslation } from "../../../../plugins/helper";
import { toolbarHeight, tabsHeight } from "../../style";

const UserSocialNetworks = () => {
  const { profileId } = useParams();
  const [profile, setProfile] = useState(null);

  const [error, setError] = useState("");
  useEffect(() => {
    if (profileId?.length > 0) {
      getProfileById(profileId)
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => {
          setError(errorParser(err));
        });
    } else setError("Invalid link");
  }, [profileId, setError]);

  return error?.length > 0 ? (
    <ErrorIllustrative error={prepareTranslation(error)} />
  ) : profile != null ? (
    <Box
      sx={{
        display: "flex",
        height: `calc(100vh - ${toolbarHeight} - ${tabsHeight} - 50px)`,
      }}
    >
      <SocialNetworksComponent
        profile={profile}
        updateSuccessFunction={() => {
          getProfileById(profileId).then((res) => setProfile(res.data));
        }}
        backNavigationLink={`/adminpanel/users/profile/${profileId}`}
        backgroundContainerColor="#efeff4"
        updateEntireProfile
      />
    </Box>
  ) : null;
};

export default UserSocialNetworks;
