import { Button } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React from "react";
import { useTranslation } from "react-i18next";
import { prepareTranslation } from "../../plugins/helper";

const DeleteButton = ({
  buttonText,
  height = "40px",
  width = "fit-content",
  buttonstyle = {},
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Button
      {...props}
      sx={{
        width: width || "fit-content",
        height: height,
        background: "#9b0909",
        ...buttonstyle,
        "&:hover": {
          background: "#9b0909b3",
        },
      }}
      variant="contained"
    >
      <DeleteOutlineOutlinedIcon
        sx={{
          marginLeft: "-0.2em",
          marginRight: "0.2em",
          marginTop: "0.5px",
        }}
      />
      {t(prepareTranslation(buttonText))}
    </Button>
  );
};

export default DeleteButton;
