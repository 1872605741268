import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerProfile } from "../../api/apis";
import DocumentsBar from "../../components/DocumentsBar";
import { FlexColumn } from "../../components/sharedComponents";
import { prepareTranslation } from "../../plugins/helper";
import { invokeSnackMessage } from "../../redux/features/coreSlice";

const Registration = () => {
  const inputWidth = { mobile: "80vw", tablet: "50vw", desktop: "30vw" };
  const buttonWidth = { mobile: "50vw", tablet: "20vw", desktop: "300px" };
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onRegisterClick = (e) => {
    e.preventDefault();
    if (
      username?.length > 0 &&
      password?.length > 0 &&
      email.length > 0 &&
      confirmPass?.length > 0
    ) {
      if (password !== confirmPass) {
        dispatch(
          invokeSnackMessage({
            message: "Passwords don't match",
            isError: true,
          })
        );
      } else {
        setLoading(true);
        registerProfile(username, password, email)
          .then((res) => {
            setLoading(false);
            navigate(`/checkmail?email=${email}`);
          })
          .catch((err) => {
            console.log("Error", err);
            setLoading(false);
          });
      }
    } else {
      dispatch(
        invokeSnackMessage({
          message: "Some fields are empty",
          isError: true,
        })
      );
    }
  };
  return (
    <>
      <FlexColumn
        style={{
          width: "100%",

          flex: 0,
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <Typography variant="h6" style={{ marginBottom: "1em" }}>
          {t(prepareTranslation("Create your MojTag! account now"))}
        </Typography>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: inputWidth,
          }}
          onSubmit={onRegisterClick}
        >
          <TextField
            disabled={loading}
            id="reg-username"
            name="reg-username"
            label={t(prepareTranslation("Username"))}
            variant="outlined"
            autoComplete="on"
            size="small"
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            sx={{
              marginBottom: "1em",
              width: inputWidth,
            }}
          />

          <TextField
            disabled={loading}
            id="reg-email"
            name="reg-email"
            size="small"
            autoComplete="on"
            label={t(prepareTranslation("Email address"))}
            variant="outlined"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            sx={{ marginBottom: "1em", width: inputWidth }}
          />

          <FormControl disabled={loading} size="small">
            <InputLabel htmlFor="reg-password">
              {t(prepareTranslation("Password"))}
            </InputLabel>
            <OutlinedInput
              id="reg-password"
              name="reg-password"
              label={t(prepareTranslation("Password"))}
              autoComplete="on"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              sx={{
                width: inputWidth,
                marginBottom: "1em",
              }}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((showPass) => !showPass)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl disabled={loading} size="small">
            <InputLabel htmlFor="confirmPassword">
              {t(prepareTranslation("Confirm password"))}
            </InputLabel>
            <OutlinedInput
              id="reg-confirmPassword"
              name="reg-confirmPassword"
              label={t(prepareTranslation("Confirm password"))}
              autoComplete="on"
              type={showRetypePassword ? "text" : "password"}
              variant="outlined"
              sx={{
                width: inputWidth,
                marginBottom: "1em",
              }}
              value={confirmPass}
              onChange={(event) => setConfirmPass(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowRetypePassword((showPass) => !showPass)
                    }
                    edge="end"
                  >
                    {showRetypePassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            sx={{
              background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              width: buttonWidth,
              color: "white",
              minHeight: "35px",
              alignSelf: "center",
              marginTop: "1em",
            }}
          >
            {t(prepareTranslation("Create profile"))}
            {loading && (
              <CircularProgress
                size={"2em"}
                sx={{
                  marginLeft: "1em",
                }}
              />
            )}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            marginBottom: "1em",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minWidth: inputWidth,
          }}
        >
          <FlexColumn style={{ flex: 0.5, marginTop: "15px" }}>
            <Link
              onClick={() => {
                navigate("/login");
              }}
              component="button"
            >
              <Typography variant="body2">
                {t(prepareTranslation("Login"))}
              </Typography>
            </Link>
          </FlexColumn>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: inputWidth,
            textAlign: "center",
            marginBottom: "1.5em",
          }}
        >
          <Typography
            style={{
              fontSize: "12px",
              maxWidth: "100%",
              whiteSpace: "break-spaces",
              overflowX: "hidden",
            }}
          >
            {t(
              prepareTranslation(
                "By registering, you accept our Terms and Conditions as well as Privacy Policy rules. More info in Help center"
              )
            ) + "."}
          </Typography>
        </Box>

        <DocumentsBar mainstyle={{ width: inputWidth }} />
      </FlexColumn>
    </>
  );
};

export default Registration;
