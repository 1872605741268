import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Snackbar,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BASIC_ROLE,
  checkUserRole,
  isLoggedIn,
  prepareTranslation,
  readNameFromProfile,
  valueHasSubstringInArray,
} from "../../plugins/helper";
import {
  getAppSettings,
  getLoggedUserData,
  resetSnackObject,
} from "../../redux/features/coreSlice";
import { FlexColumn, FlexRow } from "../sharedComponents";
import { LayoutContainer } from "../style";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import logo from "../../assets/logo.png";
import {
  clearProfileData,
  getOwnProfile,
} from "../../redux/features/profilesSlice";
import { useTheme } from "@emotion/react";
import menuItems from "./menuItems";
import { logout } from "../../redux/features/authSlice";
import { useTranslation } from "react-i18next";
import { updateUsersFilter } from "../../redux/features/usersSlice";
import DocumentsBar from "../DocumentsBar";
import InfoIllustrative from "../IllustrativeComponents/InfoIllustrative";

const Layout = ({ children }) => {
  const location = useLocation();
  const { snackObject } = useSelector((store) => store.core);
  const { ownProfile, hasOwnProfile } = useSelector((store) => store.profiles);
  const { me, token } = useSelector((store) => store.auth);
  const [noProfileDialogOpen, setNoProfileDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const noDrawerSites = ["/login", "/registration", "/reset-password"];

  const noAppBarSites = ["/login", "/adminpanel", "/moderatorpanel"];

  const { t } = useTranslation();

  const menuIconsStyle = {
    width: "1.2em",
    height: "1.2em",
    color: theme.palette.primary.main,
  };
  const menuButtonsStyle = { width: "1.5em", height: "1.5em" };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    dispatch(resetSnackObject());
  };

  useEffect(() => {
    if (me != null) {
      setDrawerOpen(false);
      //if (!me?.hasProfile) setNoProfileDialogOpen(true);
    }
  }, [me]);

  useEffect(() => {
    dispatch(getAppSettings());

    if (me != null && token != null) {
      dispatch(getLoggedUserData());
      if (checkUserRole(me?.role, BASIC_ROLE)) dispatch(getOwnProfile());
      else {
        dispatch(updateUsersFilter({ pageNumber: 1, pageSize: 10 }));
      }
    } else {
      dispatch(clearProfileData());
    }
  }, [me, token, dispatch]);

  useEffect(() => {
    if (hasOwnProfile === false && location.pathname.includes("/home")) {
      setNoProfileDialogOpen(true);
    }
  }, [location, hasOwnProfile]);

  const shouldHideDrawer = () =>
    valueHasSubstringInArray(location.pathname, noDrawerSites) ||
    me == null ||
    !checkUserRole(me?.role, BASIC_ROLE);

  const shouldHideAppBar = () =>
    location.pathname.length < 2 ||
    valueHasSubstringInArray(location.pathname, noAppBarSites);

  const onNoProfileDialogClose = () => {
    setNoProfileDialogOpen(false);
    navigate("/user-management");
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackObject.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackObject.isError ? "error" : "success"}
        >
          {snackObject?.isError
            ? snackObject.message
            : t(prepareTranslation(snackObject.message))}
        </Alert>
      </Snackbar>

      <Dialog
        open={noProfileDialogOpen}
        keepMounted
        PaperProps={{ sx: { maxWidth: "80%" } }}
        onClose={onNoProfileDialogClose}
      >
        <DialogContent sx={{ width: "fit-content", boxSizing: "border-box" }}>
          <InfoIllustrative
            header="No profile"
            text="This account has no profile. Please contact your administrator"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onNoProfileDialogClose}>
            {t(prepareTranslation("Close"))}
          </Button>
        </DialogActions>
      </Dialog>

      {me == null ||
      checkUserRole(me?.role, BASIC_ROLE) ||
      location.pathname.includes("/tag/") ? (
        <LayoutContainer>
          {!shouldHideAppBar() && (
            <FlexColumn
              style={{
                maxHeight: "100px",
              }}
            >
              {!shouldHideDrawer() && (
                <div>
                  <SwipeableDrawer
                    anchor={"left"}
                    open={drawerOpen}
                    PaperProps={{
                      sx: {
                        minWidth: "17em",
                        maxWidth: "17em",
                        overflowY: "visible",
                      },
                    }}
                    disableSwipeToOpen={false}
                    onOpen={() => {
                      setDrawerOpen(true);
                    }}
                    onClose={() => {
                      setDrawerOpen(false);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid black",
                        padding: 0,
                        overflow: "hidden",
                        maxHeight: "11em",
                        minHeight: "11em",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={ownProfile?.picture}
                        sx={{
                          filter: "blur(10px)",
                          minWidth: "100%",
                          height: "11em",
                          objectFit: "cover",
                          transform: "scale(1.2)",
                          overflow: "hidden",
                          border: "1px solid black",
                          marginRight: "-13em",
                        }}
                        alt="backgroundimg"
                      />

                      <FlexRow
                        style={{
                          position: "relative",
                          width: "100%",
                          right: "2.5em",
                          alignItems: "center",
                          color: "white",
                        }}
                      >
                        <Avatar
                          alt="User avatar"
                          src={ownProfile?.picture}
                          sx={{
                            width: "3.5em",
                            height: "3.5em",
                            marginRight: "1em",
                          }}
                        />
                        <Typography
                          style={{
                            padding: "0 1em 0 1em",
                            fontWeight: "bold",
                            fontSize: "18px",
                            background: "#817f7f4f",
                            borderRadius: "10px",
                            textAlign: "center",
                          }}
                        >
                          {readNameFromProfile(ownProfile)}
                        </Typography>
                      </FlexRow>
                    </div>

                    <List sx={{ marginTop: "-9px" }}>
                      {menuItems
                        .filter((el) => !el.needsProfile || ownProfile != null)
                        .map((el) => (
                          <ListItem
                            key={el.title}
                            sx={{
                              "&:hover":
                                el.title !== "Sign out" &&
                                location.pathname.includes(el.route)
                                  ? theme.palette.mainGradient
                                  : theme.palette.lightGradient,
                              cursor: "pointer",
                              minHeight: "2.5em",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              ...(el.title !== "Sign out" &&
                              location.pathname.includes(el.route)
                                ? theme.palette.mainGradient
                                : {}),
                            }}
                            onClick={() => {
                              if (el.title === "Sign out") {
                                dispatch(logout());
                                navigate("/login");
                              } else {
                                navigate(el.route);
                                setDrawerOpen(false);
                              }
                            }}
                          >
                            <ListItemIcon
                              style={{
                                color:
                                  el.title !== "Sign out" &&
                                  location.pathname.includes(el.route)
                                    ? "white"
                                    : "black",
                              }}
                            >
                              {el.icon}
                            </ListItemIcon>
                            <Typography style={{ marginLeft: "-0.5em" }}>
                              {t(prepareTranslation(el.title))}
                            </Typography>
                          </ListItem>
                        ))}
                    </List>
                    <FlexColumn />
                    <DocumentsBar
                      mainstyle={{ padding: "0 0.8em 1em 0.8em" }}
                    />
                  </SwipeableDrawer>
                </div>
              )}
              <FlexRow
                style={{
                  maxHeight: "70px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 0.7em 0 0.7em",
                }}
              >
                {shouldHideDrawer() ? (
                  <Box sx={menuButtonsStyle}></Box>
                ) : (
                  <IconButton
                    style={menuButtonsStyle}
                    onClick={() => {
                      setDrawerOpen((drawer) => !drawer);
                    }}
                  >
                    <MenuOutlinedIcon style={menuIconsStyle} />
                  </IconButton>
                )}
                <Box
                  component="img"
                  alt="telecomlogo"
                  src={logo}
                  sx={{
                    width: "160px",
                    height: "40px",
                    objectFit: "contain",
                    marginRight: { mobile: "-85px", tablet: "-70px" },
                  }}
                />
                <Box
                  sx={{
                    width: "100px",
                    height: "30px",
                  }}
                />
              </FlexRow>
              <FlexRow
                style={{
                  background: theme.palette.primary.background,
                  width: "100%",
                  minHeight: "30px",
                  maxHeight: "30px",
                  justifyContent: "space-between",
                  boxSizing: "border-box",
                  padding: "0 1.8em 0 1.8em",
                }}
              >
                {checkUserRole(me?.role, BASIC_ROLE) && isLoggedIn() && (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#757575",
                      }}
                    >
                      <VisibilityOutlinedIcon
                        style={{
                          width: "22px",
                          height: "22px",
                          marginRight: "0.3em",
                          paddingTop: "1px",
                        }}
                      />
                      <Typography style={{ paddingBottom: "3px" }}>
                        {ownProfile?.visitsNumber || 0}
                      </Typography>
                    </Box>
                  </>
                )}
              </FlexRow>
            </FlexColumn>
          )}

          {children}
        </LayoutContainer>
      ) : (
        children
      )}
    </>
  );
};

export default Layout;
