import { Box, CircularProgress, MenuItem, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllUserGroups } from "../../api/apis";
import { availableRoles, prepareTranslation } from "../../plugins/helper";
import SaveButton from "../Buttons/SaveButton";

const UserForm = ({
  submitFunction,
  submitSuccessFunction,
  buttonText,
  currentUser,
  formNeedsFilled = true,
  formstyle = {},
}) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [userGroups, setUserGroups] = useState([]);
  const [userGroup, setUserGroup] = useState("");

  const resetForm = useCallback(() => {
    setUsername("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setRole("");
    setUserGroup("");

    setLoading(false);
  }, []);

  const formFilled = () => {
    return (
      firstName?.length > 0 &&
      lastName?.length > 0 &&
      email?.length > 0 &&
      role?.length > 0 &&
      username?.length > 0
    );
  };

  const { t } = useTranslation();

  useEffect(() => {
    resetForm();
    if (currentUser != null) {
      setUsername(currentUser?.userName || "");
      setFirstName(currentUser?.firstName || "");
      setLastName(currentUser?.lastName || "");
      setEmail(currentUser?.email || "");
      setUserGroup(currentUser?.userGroup?.id || "");
      setRole(currentUser?.role || "");
    }
    getAllUserGroups().then((res) => {
      setUserGroups(res.data.list);
    });
  }, [resetForm, currentUser]);

  const onSubmitClick = (e) => {
    e.preventDefault();
    setLoading(true);
    let reqObj = {
      firstName,
      lastName,
      email,
      username,
      roles: [role],
      profilesQuantity: 1,
    };
    if (userGroup !== "") reqObj["userGroupId"] = userGroup;
    if (currentUser != null) reqObj["id"] = currentUser.id;
    submitFunction(reqObj)
      .then((_res) => {
        submitSuccessFunction();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "2em 2em",
        ...formstyle,
      }}
      onSubmit={onSubmitClick}
    >
      <TextField
        disabled={loading}
        size="small"
        id="reg-firstname"
        name="reg-firstname"
        label={t(prepareTranslation("First name")) + "*"}
        variant="outlined"
        autoComplete="on"
        value={firstName}
        onChange={(event) => {
          setFirstName(event.target.value);
        }}
        sx={{
          marginBottom: "1em",
        }}
      />
      <TextField
        disabled={loading}
        size="small"
        id="reg-lastname"
        name="reg-lastname"
        label={t(prepareTranslation("Last name")) + "*"}
        variant="outlined"
        autoComplete="on"
        value={lastName}
        onChange={(event) => {
          setLastName(event.target.value);
        }}
        sx={{
          marginBottom: "1em",
        }}
      />
      <TextField
        disabled={loading}
        size="small"
        id="reg-username"
        name="reg-username"
        label={t(prepareTranslation("Username")) + "*"}
        variant="outlined"
        autoComplete="on"
        value={username}
        onChange={(event) => {
          if (event.target.value.match(/^[a-z_.]*$/))
            setUsername(event.target.value);
        }}
        sx={{
          marginBottom: "1em",
        }}
      />

      <TextField
        disabled={loading}
        size="small"
        id="reg-email"
        name="reg-email"
        autoComplete="on"
        label={t(prepareTranslation("Email address")) + "*"}
        variant="outlined"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        sx={{ marginBottom: "1em" }}
      />

      <TextField
        disabled={loading}
        size="small"
        sx={{ marginBottom: "1em" }}
        select
        id="demo-simple-select-labsel"
        value={role}
        label={t(prepareTranslation("Role")) + "*"}
        onChange={(e) => setRole(e.target.value)}
      >
        <MenuItem value="">
          <em>{t(prepareTranslation("None"))}</em>
        </MenuItem>
        {availableRoles.map((el) => (
          <MenuItem key={el} value={el}>
            {el}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        disabled={loading}
        select
        size="small"
        sx={{ marginBottom: "1em" }}
        id="demo-simple-select-label"
        value={userGroup}
        label={t(prepareTranslation("User group"))}
        onChange={(e) => setUserGroup(e.target.value)}
      >
        <MenuItem value="">
          <em>{t(prepareTranslation("None"))}</em>
        </MenuItem>
        {userGroups.map((el) => (
          <MenuItem key={el.id} value={el.id}>
            {el.name}
          </MenuItem>
        ))}
      </TextField>

      <SaveButton
        disabled={loading || (formNeedsFilled && !formFilled())}
        variant="contained"
        type="submit"
        buttonText={buttonText}
        buttonstyle={{ alignSelf: "center", marginTop: "1em" }}
      >
        {loading && (
          <CircularProgress
            size={"1.5em"}
            sx={{
              marginLeft: "1em",
            }}
          />
        )}
      </SaveButton>
    </Box>
  );
};

export default UserForm;
