import jwtDecode from "jwt-decode";

export const getLocalRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const getLocalAccessToken = () => {
  return localStorage.getItem("token");
};

export const setLocalAccessToken = (token) => {
  localStorage.setItem("token", token);
  setMeFromToken(token);
};

export const setLocalRefreshToken = (refreshToken) => {
  localStorage.setItem("refreshToken", refreshToken);
};

export const setMeFromToken = (token) => {
  localStorage.setItem("me", JSON.stringify(jwtDecode(token)));
};

export const getMe = () => {
  return JSON.parse(localStorage.getItem("me"));
};

export const getLanguagePreference = () => localStorage.getItem("lang");

export const setLanguagePreference = (lang) => {
  localStorage.setItem("lang", lang);
};

export const removeMe = () => {
  localStorage.removeItem("me");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
};
