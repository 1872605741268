import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { FlexColumn, FlexRow } from "../sharedComponents";
import { prepareTranslation } from "../../plugins/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ConfirmationDialog = ({
  confirmDialogOpen,
  setConfirmDialogOpen,
  confirmAction,
  message,
  negativeAction = null,
  includeLoader = false,
  confirmWarningMessage = "",
  setConfirmWarningMessage = null,
}) => {
  const { t } = useTranslation();
  const [disableButtons, setDisableButtons] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (confirmDialogOpen) {
      setDisableButtons(false);
      setLoading(false);
    }
  }, [confirmDialogOpen]);

  useEffect(() => {
    if (!confirmDialogOpen && setConfirmWarningMessage != null) {
      setConfirmWarningMessage("");
    }
  }, [confirmDialogOpen, setConfirmWarningMessage]);

  return (
    <>
      <Dialog
        open={confirmDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="lg"
        sx={{ zIndex: 9999 }}
      >
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "600px",
          }}
        >
          <FlexColumn
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            {message?.length > 0 && (
              <Typography
                variant="h6"
                style={{ marginBottom: "1.5em", maxWidth: "300px" }}
              >
                {t(prepareTranslation(message))}
              </Typography>
            )}
            {confirmWarningMessage?.length > 0 && (
              <FlexRow>
                <WarningAmberOutlinedIcon
                  style={{
                    color: "#d79211",
                    marginRight: "0.2em",
                    marginTop: "0.15em",
                  }}
                />
                <Typography style={{ marginBottom: "1em" }} variant="subtitle1">
                  {t(confirmWarningMessage)}
                </Typography>
              </FlexRow>
            )}
            <Typography style={{ marginBottom: "1em" }} variant="subtitle1">
              {t(prepareTranslation("Are you sure")) + "?"}
            </Typography>
            {includeLoader && loading && (
              <FlexRow
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <CircularProgress />
              </FlexRow>
            )}
          </FlexColumn>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (negativeAction != null) negativeAction();
              else setConfirmDialogOpen(false);
            }}
            disabled={disableButtons}
            color="primary"
          >
            {t(prepareTranslation("No"))}
          </Button>
          <Button
            disabled={disableButtons}
            onClick={() => {
              setDisableButtons(true);
              setLoading(true);
              confirmAction(setLoading);
            }}
            color="primary"
          >
            {t(prepareTranslation("Yes"))}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
