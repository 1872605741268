import styled from "@emotion/styled";
import { IconButton, List } from "@mui/material";

export const StyledSocialIconButton = styled(IconButton)({
  width: "1.4em",
  height: "1.4em",
  margin: "0 0.1em 0 0.1em",
  "& .MuiSvgIcon-root": {
    width: "1em",
    height: "1em",
    color: "#9a9999",
  },
});

export const StyledList = styled(List)(({ theme }) => ({
  "::-webkit-scrollbar": {
    width: "5px",
    height: "6px",
  },

  /* Track */
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },

  /* Handle */
  "::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
  },

  /* Handle on hover */
  "::-webkit-scrollbar-thumb:hover": {
    background: "#918e66",
  },
}));
