import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { AccountCircleOutlined } from "@mui/icons-material";

const menuItems = [
  {
    title: "Homepage",
    route: "home",
    icon: <HomeOutlinedIcon />,
    needsProfile: true,
  },
  {
    title: "My profile",
    route: "profile",
    icon: <PortraitOutlinedIcon />,
    needsProfile: true,
  },
  {
    title: "Connections",
    route: "connections",
    icon: <Diversity1OutlinedIcon />,
    needsProfile: true,
  },

  {
    title: "Help center",
    route: "help-center",
    icon: <HelpCenterOutlinedIcon />,
  },
  {
    title: "My account",
    route: "user-management",
    icon: <AccountCircleOutlined />,
  },
  {
    title: "Sign out",
    icon: <LogoutOutlinedIcon />,
  },
];

export default menuItems;
