import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { forgotPassword, forgotUsername } from "../../api/apis";
import logo from "../../assets/logo.png";
import { FlexColumn, FlexRow } from "../../components/sharedComponents";
import { prepareTranslation } from "../../plugins/helper";
import { login } from "../../redux/features/authSlice";
import { invokeSnackMessage } from "../../redux/features/coreSlice";
import DocumentsBar from "../../components/DocumentsBar";
import LanguageSwitcherHorizontal from "../../components/LanguageSwitcherHorizontal/languageSwitcher"; 

const Login = () => {
  const inputWidth = { mobile: "300px", tablet: "400px" };
  const { loading } = useSelector((store) => store.auth);
  const { appSettings } = useSelector((store) => store.core);
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [resetDrawerOpen, setResetDrawerOpen] = useState(false);
  const [resetDrawerTitle, setResetDrawerTitle] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [resetLoading, setResetLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  const closeDrawer = (event) => {
    if (
      event != null &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setResetDrawerOpen(false);
    setTimeout(() => setResetEmail(""), 1000);
  };

  const onLoginClick = (e) => {
    e.preventDefault();

    if (username?.length > 0 && password?.length > 0) {
      dispatch(login({ username, password }))
        .unwrap()
        .then(() => {
          if (searchParams.get("redirectusertag"))
            navigate(`/tag/${searchParams.get("redirectusertag")}`);
        });
    } else {
      setPassword("");
      dispatch(
        invokeSnackMessage({
          message: "Username/Password empty",
          isError: true,
        })
      );
    }
  };
  return (
    <>
      <FlexColumn
        style={{
          width: "100%",
          maxHeight: "100vh",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "-1em",
        }}
      >
        <Drawer
          anchor={"bottom"}
          open={resetDrawerOpen}
          onClose={closeDrawer}
          PaperProps={{
            sx: {
              margin: "auto",
              width: { mobile: "100%", tablet: "580px", desktop: "800px" },
              height: "19em",
            },
          }}
        >
          <FlexColumn
            style={{
              padding: "1em",
              justifyContent: "space-between",
              alignItems: "center",
              width: inputWidth,
            }}
          >
            <Typography variant="h6">
              {t(prepareTranslation(resetDrawerTitle))}
            </Typography>
            <Typography
              variant="body1"
              sx={{ width: { mobile: "300px", tablet: "fit-content" } }}
            >
              {t(
                prepareTranslation(
                  "Enter your email address used during registration"
                )
              )}
            </Typography>
            <TextField
              disabled={resetLoading}
              id="email"
              label={t(prepareTranslation("Email address"))}
              variant="outlined"
              sx={{ width: inputWidth }}
              value={resetEmail}
              onChange={(event) => setResetEmail(event.target.value)}
            />
            <Button
              disabled={!(resetEmail?.length > 0) || resetLoading}
              variant="contained"
              color="alternate"
              sx={{
                width: inputWidth,
                height: "48px",
                marginBottom: "1.5em",
              }}
              onClick={() => {
                if (resetDrawerTitle === "Forgot username") {
                  setResetLoading(true);
                  forgotUsername(resetEmail)
                    .then(() => {
                      setResetLoading(false);
                      dispatch(
                        invokeSnackMessage({
                          message:
                            "Your forgotten username has been sent to your email",
                          isError: false,
                        })
                      );

                      closeDrawer();
                    })
                    .catch((err) => {
                      setResetLoading(false);
                    });
                } else if (resetDrawerTitle === "Forgot password") {
                  setResetLoading(true);
                  forgotPassword(resetEmail)
                    .then(() => {
                      setResetLoading(false);
                      dispatch(
                        invokeSnackMessage({
                          message:
                            "Password reset link has been sent to your email address",
                          isError: false,
                        })
                      );

                      closeDrawer();
                    })
                    .catch((err) => {
                      setResetLoading(false);
                    });
                }
              }}
            >
              {t(prepareTranslation("Send"))}
              {resetLoading && (
                <CircularProgress
                  size={"2em"}
                  sx={{
                    color: "white",
                    marginLeft: "1em",
                  }}
                />
              )}
            </Button>
          </FlexColumn>
        </Drawer>
        <Box
          component="img"
          alt="telecomlogo"
          src={logo}
          sx={{
            width: "240px",
            height: "60px",

            marginBottom: "2em",
          }}
        />
        <Box
          component="form"
          style={{ display: "flex", flexDirection: "column" }}
          onSubmit={onLoginClick}
        >
          <TextField
            disabled={loading}
            id="username"
            label={t(prepareTranslation("Username"))}
            variant="outlined"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            sx={{ marginBottom: "2em", width: inputWidth }}
          />

          <FormControl disabled={loading}>
            <InputLabel htmlFor="outlined-adornment-password">
              {t(prepareTranslation("Password"))}
            </InputLabel>
            <OutlinedInput
              id="password"
              name="password"
              label={t(prepareTranslation("Password"))}
              autoComplete="on"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              sx={{
                width: inputWidth,
                marginBottom: "2em",
              }}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((showPass) => !showPass)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Button
            variant="contained"
            type="submit"
            sx={{
              ...theme.palette.mainGradient,
              width: inputWidth,

              minHeight: "40px",
              marginBottom: "1.5em",
            }}
            disabled={loading}
          >
            {t(prepareTranslation("Sign in"))}
            {loading && (
              <CircularProgress
                size={"2em"}
                sx={{
                  color: theme.palette.loader.main,
                  marginLeft: "1em",
                }}
              />
            )}
          </Button>
          <LanguageSwitcherHorizontal />
        </Box>
        {appSettings?.canRegister && (
          <FlexRow
            style={{
              display: "flex",
              alignItems: "center",
              maxHeight: "50px",
            }}
          >
            <Typography type="body2">
              {t(prepareTranslation("Don't have an account yet")) + "?"}
            </Typography>

            <Link
              onClick={() => {
                navigate("/registration");
              }}
              component="button"
              style={{ marginLeft: "5px" }}
            >
              <Typography variant="body2">
                {t(prepareTranslation("Create one now"))}
              </Typography>
            </Link>
          </FlexRow>
        )}
        <Box
          component="div"
          sx={{
            display: "flex",
            maxHeight: "50px",
            flex: 1,
            justifyContent: "space-between",
            width: inputWidth,
          }}
        >
          <Link
            component="button"
            onClick={() => {
              setResetDrawerOpen(true);
              setResetDrawerTitle("Forgot username");
            }}
          >
            {t(prepareTranslation("Forgot username")) + "?"}
          </Link>
          <Link
            component="button"
            onClick={() => {
              setResetDrawerOpen(true);
              setResetDrawerTitle("Forgot password");
            }}
          >
            {t(prepareTranslation("Forgot password")) + "?"}
          </Link>
        </Box>
      </FlexColumn>
      <DocumentsBar
        mainstyle={{
          flexDirection: "column",
          alignItems: "center",
          minHeight: "35px",
          marginBottom: "2em",
          minWidth: "100%",

          "& a": {
            textAlign: "center",
          },
        }}
      />
    </>
  );
};

export default Login;
