import { useTheme } from "@emotion/react";
import { Avatar, Grid, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import { FlexColumn } from "../../components/sharedComponents";

const SocialGrid = forwardRef(
  ({ directfirstactive, el, index, ...props }, ref) => {
    const theme = useTheme();
    return (
      <Grid
        ref={ref}
        item
        style={{ margin: "0.5em" }}
        name={`${index}`}
        {...props}
      >
        <FlexColumn
          style={{
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "84px",
            minWidth: "84px",
            height: "90px",
            background: "white",
            borderRadius: "10px",
            padding: "4px 4px 0 4px",
            border:
              directfirstactive && index === 0
                ? `1px solid ${theme.palette.primary.main}`
                : "0",
          }}
        >
          <Avatar
            variant="square"
            src={el.logo}
            sx={{
              cursor: "pointer",
              width: "48px",
              height: "48px",
              opacity: directfirstactive && index > 0 ? "0.5" : "1",
              ".MuiAvatar-img": {
                WebkitUserDrag: "none",
              },
            }}
            onClick={() => {
              window.open(el.href + el.data, "_blank");
            }}
          />
          <Typography
            style={{
              fontWeight: "600",
              marginTop: "5px",
              fontSize: "12px",
              textAlign: "center",
              color: directfirstactive && index > 0 ? "gray" : "black",
            }}
          >
            {el.name}
          </Typography>
        </FlexColumn>
      </Grid>
    );
  }
);

export default SocialGrid;
