import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getConnectionProfile, incrementVisitsNumber } from "../../api/apis";
import ProfileWithSocialMedia from "../../components/ProfileWithSocialMedia";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import { Button } from "@mui/material";
import { prepareTranslation } from "../../plugins/helper";
import { useTranslation } from "react-i18next";

const Connection = () => {
  const [profile, setProfile] = useState(null);
  const { username } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (username != null) {
      getConnectionProfile(username).then((res) => {
        setProfile(res.data);
        incrementVisitsNumber(res.data.id);
      });
    }
  }, [username]);

  const iconResponsiveWidth = {
    mobile: "0.7em",
    tablet: "1em",
    desktop: "1em",
  };

  return profile != null ? (
    <>
      <Button
        onClick={() => navigate("/connections")}
        sx={{
          width: { mobile: "100px", tablet: "150px", desktop: "150px" },
          height: "35px",
          marginLeft: "1em",
          position: "relative",
          left: 0,
          top: "20px",
          fontSize: { mobile: "10px", tablet: "13px", desktop: "13px" },
          display: "flex",
          alignItems: "center",
        }}
        variant="outlined"
      >
        <AssignmentReturnOutlinedIcon
          sx={{
            marginRight: { mobile: "0.1em", tablet: "0.2em", desktop: "0.3em" },
            marginLeft: {
              mobile: "-0.1em",
              tablet: "-0.2em",
              desktop: "-0.3em",
            },
            width: iconResponsiveWidth,
          }}
        />
        {t(prepareTranslation("Connections"))}
      </Button>
      <ProfileWithSocialMedia profile={profile} containerMarginTop="-50px" />
    </>
  ) : null;
};

export default Connection;
