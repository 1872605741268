import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  addSocialNetwork,
  deleteSocialNetwork,
  updateSocialNetwork,
} from "../../../api/apis";
import { getSocialNetworks } from "../../../redux/features/socialNetworksSlice";
import {
  FlexRow,
  StyledDialogContent,
} from "../../../components/sharedComponents";
import { prepareTranslation } from "../../../plugins/helper";
import { useSelector } from "react-redux";
import { invokeSnackMessage } from "../../../redux/features/coreSlice";
import { Box } from "@mui/system";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import imageCompression from "browser-image-compression";

const SocialNetworkDialog = ({
  open,
  setOpen,
  currentSocialNetwork,
  setCurrentSocialNetwork,
  setConfirmAction,
  setConfirmMessage,
  setConfirmDialogOpen,
  action,
}) => {
  const handleDialogClose = () => {
    setOpen(false);
  };
  const fileRef = useRef();
  const { socialNetworksFilter } = useSelector((store) => store.socialNetworks);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [href, setHref] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageChanged, setImageChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const refetchCurrentSocialNetwork = () => {
    let currentSocialNetworkId = currentSocialNetwork.id;
    dispatch(getSocialNetworks(socialNetworksFilter))
      .unwrap()
      .then((res) => {
        setCurrentSocialNetwork(
          res.data.find((el) => el.id === currentSocialNetworkId)
        );
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };

  const socialNetworkUpdate = () => {
    const formData = new FormData();
    formData.append("id", currentSocialNetwork.id);
    formData.append("uniqueName", name);
    formData.append("placeholder", name);
    if (description?.length > 0) formData.append("description", description);
    if (href?.length > 0) formData.append("href", href);
    if (logo != null && imageChanged) {
      formData.append("changeLogo", true);
      formData.append("logo", logo, imageName);
    }

    updateSocialNetwork(formData)
      .then((_res) => {
        refetchCurrentSocialNetwork();
        dispatch(
          invokeSnackMessage({
            message: "Successfully updated socialNetwork",
            isError: false,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const socialNetworkAdd = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("uniqueName", name);
    formData.append("placeholder", name);
    if (description?.length > 0) formData.append("description", description);
    if (href?.length > 0) formData.append("href", href);
    if (logo != null && imageChanged) formData.append("logo", logo, imageName);

    addSocialNetwork(formData)
      .then((_res) => {
        dispatch(
          invokeSnackMessage({
            message: "Successfully added socialNetwork",
            isError: false,
          })
        );
        dispatch(getSocialNetworks(socialNetworksFilter)).then(() => {
          setLoading(false);
          setOpen(false);
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  const mandatoryFieldsFilled = () => {
    return name?.length > 0;
  };

  useEffect(() => {
    if (open) {
      setName(action === "update" ? currentSocialNetwork?.name || "" : "");
      setDescription(
        action === "update" ? currentSocialNetwork?.description || "" : ""
      );
      setLogo(
        action === "update" && currentSocialNetwork?.logo != null
          ? currentSocialNetwork.logo
          : null
      );
      setHref(action === "update" ? currentSocialNetwork?.href || "" : "");

      setTempImage(null);

      setImageChanged(false);

      setImageName("");
    }
  }, [action, currentSocialNetwork, open]);

  const handleFileSelect = (event) => {
    imageCompression(event.target.files[0], {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1920,
    }).then((res) => {
      setImageChanged(true);
      setTempImage(URL.createObjectURL(res));
      setLogo(res);
    });
    setImageName(event.target.files[0].name);
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      PaperProps={{ sx: { maxWidth: "80%" } }}
      open={open}
      onClose={handleDialogClose}
    >
      <DialogTitle>
        {action === "update" ? (
          <>
            <span>{t(prepareTranslation("Edit socialNetwork"))}</span>{" "}
            <i>{` - ${currentSocialNetwork?.name}`} </i>
          </>
        ) : (
          t(prepareTranslation("Add new social network"))
        )}
      </DialogTitle>
      <StyledDialogContent sx={{ width: "100%", boxSizing: "border-box" }}>
        <input
          onClick={(e) => (e.target.value = null)}
          ref={fileRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileSelect}
          accept="image/*"
        />
        <FlexRow
          style={{ margin: "1em 0 1em 0", justifyContent: "space-around" }}
        >
          <Avatar
            variant="square"
            onClick={() => {
              fileRef.current.click();
            }}
            style={{
              cursor: "pointer",
              width: "100px",
              height: "100px",
            }}
            imgProps={{ style: { objectFit: "contain" } }}
            src={tempImage != null ? tempImage : logo}
          >
            {(action === "add" || (!logo && !tempImage)) && (
              <AddAPhotoOutlinedIcon sx={{ width: "1.5em", height: "1.5em" }} />
            )}
          </Avatar>
        </FlexRow>

        <FlexRow style={{ justifyContent: "center", marginBottom: "2em" }}>
          <Typography
            sx={{ fontSize: "20px", fontWeight: "500", textAlign: "center" }}
          >
            {t(prepareTranslation("Logo"))}
          </Typography>
        </FlexRow>

        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            padding: "1em",
            boxSizing: "border-box",
          }}
        >
          <FlexRow style={{ marginBottom: "1.5em" }}>
            <TextField
              disabled={loading}
              sx={{ marginRight: "2em", flex: 1 }}
              value={name}
              label={t(prepareTranslation("SocialNetwork name")) + "*"}
              variant="standard"
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              disabled={loading}
              sx={{ flex: 1 }}
              value={href}
              label={t(prepareTranslation("Base link")) + "*"}
              variant="standard"
              onChange={(e) => setHref(e.target.value)}
            />
          </FlexRow>

          <FlexRow>
            <TextField
              multiline
              minRows={4}
              disabled={loading}
              sx={{ flex: 1 }}
              value={description}
              label={t(prepareTranslation("Description"))}
              variant="outlined"
              onChange={(e) => setDescription(e.target.value)}
            />
          </FlexRow>

          {action === "update" && currentSocialNetwork != null && (
            <FlexRow style={{ justifyContent: "center", marginTop: "2em" }}>
              <DeleteButton
                width="fit-content"
                onClick={() => {
                  setConfirmMessage("Delete socialNetwork");
                  setConfirmAction(() => (setLoading) => {
                    setLoading(true);
                    deleteSocialNetwork(currentSocialNetwork.id)
                      .then((res) => {
                        dispatch(
                          invokeSnackMessage({
                            message: "SocialNetwork successfully deleted",
                            isError: false,
                          })
                        );
                      })
                      .finally(() => {
                        dispatch(getSocialNetworks(socialNetworksFilter)).then(
                          () => {
                            setLoading(false);
                            setConfirmDialogOpen(false);
                            setOpen(false);
                          }
                        );
                      });
                  });
                  setConfirmDialogOpen(true);
                }}
                buttonText="Delete socialNetwork"
              />
            </FlexRow>
          )}
        </Box>
      </StyledDialogContent>
      <DialogActions>
        <Button
          disabled={!mandatoryFieldsFilled() || loading}
          onClick={action === "update" ? socialNetworkUpdate : socialNetworkAdd}
        >
          {t(prepareTranslation(action === "update" ? "Save" : "Add"))}
        </Button>
        <Button disabled={loading} onClick={handleDialogClose}>
          {t(prepareTranslation("Close"))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SocialNetworkDialog;
