import axiosInstance from "./config";

export const registerProfile = (username, password, email) => {
  return axiosInstance.post("/Auth/register-profile", {
    username,
    password,
    email,
    clientUri: window.location.origin + "/activation",
  });
};

export const addUser = (reqObj) => {
  return axiosInstance.post("Users", reqObj);
};

export const addProfile = (reqObj) => {
  return axiosInstance.post("Profiles", reqObj, {
    requestType: "multipart/form-data",
  });
};

export const activateViaMailLink = (userName, token) => {
  return axiosInstance.post("/Auth/confirm-email", {
    userName,
    token,
  });
};

export const getAllUserGroups = () => {
  return axiosInstance.post("/UserGroups/search", { page: 1, pageSize: 5000 });
};

export const deleteUserGroup = (groupId) => {
  return axiosInstance.delete(`/UserGroups/${groupId}`);
};

export const addUserGroup = (formData) => {
  return axiosInstance.post("/UserGroups/insert-eager", formData, {
    requestType: "multipart/form-data",
  });
};

export const updateUserGroup = (formData) => {
  return axiosInstance.put("/UserGroups/update-eager", formData);
};
export const getProfileByUsername = (userName) => {
  return axiosInstance.get(`/Profiles/get-by-username/${userName}`);
};

export const getProfileByUserId = (userId) => {
  return axiosInstance.get(`/Profiles/get-primary-by-userId/${userId}`);
};

export const getProfileById = (profileId) => {
  return axiosInstance.get(`Profiles/${profileId}`);
};

export const forgotPassword = (email) => {
  return axiosInstance.post("/Auth/forgot-password", {
    email,
    clientUri: window.location.origin + "/reset-password",
  });
};

export const forgotUsername = (email) => {
  return axiosInstance.post("/Auth/forgot-username", {
    email,
    clientUri: window.location.origin + "/login",
  });
};

export const resetPassword = (email, token, password, confirmedPassword) => {
  return axiosInstance.post("/Auth/reset-password", {
    email,
    token,
    password,
    confirmedPassword,
  });
};

export const resetUsername = (email, token, username, confirmedUsername) => {
  return axiosInstance.post("/Auth/reset-username", {
    email,
    token,
    username,
    confirmedUsername,
  });
};

export const deleteUser = (userId) => {
  return axiosInstance.delete(`/Users/${userId}`);
};

export const getAllSocialNetworks = () => {
  return axiosInstance.get("/SocialNetworks/get-all");
};

export const updateSocialNetworkOrder = (newOrder, profileId) => {
  return axiosInstance.put("/Profiles/update-social-network-order", {
    collection: newOrder,
    profileId,
  });
};

export const updateSocialNetwork = (formData) => {
  return axiosInstance.put("/SocialNetworks", formData, {
    requestType: "multipart/form-data",
  });
};

export const addSocialNetwork = (formData) => {
  return axiosInstance.post("/SocialNetworks", formData, {
    requestType: "multipart/form-data",
  });
};

export const deleteSocialNetwork = (id) => {
  return axiosInstance.delete(`/SocialNetworks/${id}`);
};

export const updateProfile = (requestForm) => {
  return axiosInstance.put("Profiles/update-own", requestForm, {
    requestType: "multipart/form-data",
  });
};

export const updateOuterProfile = (requestForm) => {
  return axiosInstance.put("Profiles", requestForm, {
    requestType: "multipart/form-data",
  });
};

export const updateUser = (requestForm) => {
  return axiosInstance.put("Users", requestForm);
};

export const deleteProfile = (id) => {
  return axiosInstance.delete(`Profiles/delete-own/${id}`);
};

export const deleteOuterProfile = (id) => {
  return axiosInstance.delete(`Profiles/${id}`);
};

export const updateProfileSocialNetworks = (updatePayload) => {
  return axiosInstance.put("Profiles/update-social-networks", updatePayload);
};

export const getConnectionProfile = (username) => {
  return axiosInstance.get(`Profiles/get-by-username/${username}`);
};

export const incrementVisitsNumber = (profileId) => {
  return axiosInstance.put(`Profiles/increment-visits-number/${profileId}`);
};

export const getHelpCenterData = () => {
  return axiosInstance.post("HelpCenter/search", { page: 1, pageSize: 5000 });
};

export const deleteConnection = (userId) => {
  return axiosInstance.delete("Connections", { data: { userId } });
};

export const addConnection = (userId) => {
  return axiosInstance.post("Connections", { userId });
};

export const updateBasicsLoggedUser = (reqObj) => {
  return axiosInstance.put("Users/self", reqObj);
};

export const changePasswordLoggedUser = (reqObj) => {
  return axiosInstance.put("Users/change-password", reqObj);
};

export const changeUsernameLoggedUser = (reqObj) => {
  return axiosInstance.put("Users/change-username", reqObj);
};
