import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  addUserGroup,
  deleteUserGroup,
  updateUserGroup,
} from "../../../api/apis";
import { getGroups } from "../../../redux/features/groupsSlice";
import {
  FlexColumn,
  FlexRow,
  StyledDialogContent,
} from "../../../components/sharedComponents";
import { prepareTranslation } from "../../../plugins/helper";
import { useSelector } from "react-redux";
import { invokeSnackMessage } from "../../../redux/features/coreSlice";
import { Box } from "@mui/system";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import imageCompression from "browser-image-compression";
import { ClearOutlined, InfoOutlined } from "@mui/icons-material";

const GroupDialog = ({
  open,
  setOpen,
  currentGroup,
  setCurrentGroup,
  setConfirmAction,
  setConfirmMessage,
  setConfirmDialogOpen,
  action,
}) => {
  const BANER_ACCEPT_WIDTH = 320,
    BANER_ACCEPT_HEIGHT = 50;

  const handleDialogClose = () => {
    setOpen(false);
  };
  const fileRef = useRef();
  const { groupsFilter } = useSelector((store) => store.groups);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [baner1, setBaner1] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [imageChanged, setImageChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const refetchCurrentGroup = () => {
    let currentGroupId = currentGroup.id;
    dispatch(getGroups(groupsFilter))
      .unwrap()
      .then((res) => {
        setCurrentGroup(res.list.find((el) => el.id === currentGroupId));
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };

  const groupUpdate = () => {
    const formData = new FormData();
    formData.append("id", currentGroup.id);
    formData.append("name", name);
    formData.append("description", description);
    if (imageChanged) {
      if (baner1 == null) formData.append("Photo", "");
      else formData.append("Photo", baner1, imageName);
      formData.append("PhotoChanged", true);
    }
    updateUserGroup(formData)
      .then((_res) => {
        refetchCurrentGroup();
        dispatch(
          invokeSnackMessage({
            message: "Successfully updated user group",
            isError: false,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const groupAdd = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    if (description?.length > 0) formData.append("description", description);
    if (baner1 != null && imageChanged) {
      formData.append("Photo", baner1, imageName);
    }
    addUserGroup(formData)
      .then((_res) => {
        dispatch(
          invokeSnackMessage({
            message: "Successfully added user group",
            isError: false,
          })
        );
      })
      .finally(() => {
        dispatch(getGroups(groupsFilter)).then(() => {
          setLoading(false);
          setOpen(false);
        });
      });
  };

  const mandatoryFieldsFilled = () => {
    return name?.length > 0;
  };

  useEffect(() => {
    if (open) {
      setName(action === "update" ? currentGroup?.name || "" : "");
      setDescription(
        action === "update" ? currentGroup?.description || "" : ""
      );
      setBaner1(
        action === "update" && currentGroup?.banners?.length > 0
          ? currentGroup.banners[0].photoUri
          : null
      );

      setTempImage(null);

      setImageChanged(false);

      setImageName("");
    }
  }, [action, currentGroup, open]);

  const handleFileSelect = (event) => {
    imageCompression(event.target.files[0], {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1920,
    }).then((res) => {
      const image = new Image();
      image.src = URL.createObjectURL(res);
      image.addEventListener("load", () => {
        console.log("Image width height", `${image.width}x${image.height}`);
        if (
          image.width !== BANER_ACCEPT_WIDTH ||
          image.height !== BANER_ACCEPT_HEIGHT
        )
          dispatch(
            invokeSnackMessage({
              message: "Image size is not acceptable",
              isError: true,
            })
          );
        else {
          setImageName(event.target.files[0].name);
          setImageChanged(true);
          setTempImage(URL.createObjectURL(res));
          setBaner1(res);
        }
      });
    });
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      PaperProps={{ sx: { maxWidth: "80%" } }}
      open={open}
      onClose={handleDialogClose}
    >
      <DialogTitle>
        {action === "update" ? (
          <>
            <span>{t(prepareTranslation("Edit group"))}</span>
            <i>{` - ${currentGroup?.name}`}</i>
          </>
        ) : (
          t(prepareTranslation("Add new group"))
        )}
      </DialogTitle>
      <StyledDialogContent sx={{ width: "100%", boxSizing: "border-box" }}>
        <input
          onClick={(e) => (e.target.value = null)}
          ref={fileRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileSelect}
          accept="image/*"
        />

        <FlexRow
          style={{
            margin: "1.5em 0 1em 0",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FlexColumn
            style={{ justifyContent: "center", alignItems: "center", flex: 0 }}
          >
            <Avatar
              variant="square"
              onClick={() => {
                fileRef.current.click();
              }}
              style={{
                width: "320px",
                height: "50px",
                border: "1px solid gray",
                cursor: "pointer",
                background: "#e1e1e1",
              }}
              imgProps={{ style: { objectFit: "contain" } }}
              src={tempImage || baner1}
            >
              {(action === "add" || (!baner1 && !tempImage)) && (
                <AddAPhotoOutlinedIcon
                  sx={{ width: "1.5em", height: "1.5em" }}
                />
              )}
            </Avatar>
            <Typography
              sx={{ margin: "1em 0 1em 0", fontWeight: 600, fontSize: "18px" }}
            >
              {t(prepareTranslation("Baner"))}
            </Typography>
          </FlexColumn>
          <Tooltip
            title={
              t(prepareTranslation("Acceptable baner image format is")) +
              ` - ${BANER_ACCEPT_WIDTH}x${BANER_ACCEPT_HEIGHT}`
            }
          >
            <InfoOutlined
              sx={{
                color: "gray",
                marginLeft: "0.5em",
                marginTop: "-2.5em",
              }}
            />
          </Tooltip>
          {(tempImage != null || baner1 != null) && (
            <IconButton
              sx={{
                marginLeft: "0.3em",
                color: "red",
                width: "1.2em",
                height: "1.2em",
                marginTop: "-2.5em",
              }}
              onClick={() => {
                setTempImage(null);
                setBaner1(null);
                setImageChanged(true);
                setImageName("");
              }}
            >
              <Tooltip title={t(prepareTranslation("Delete baner"))}>
                <ClearOutlined sx={{ width: "1.2em", height: "1.2em" }} />
              </Tooltip>
            </IconButton>
          )}
        </FlexRow>

        <Box
          component="form"
          sx={{
            display: "flex",
            flex: 1,
            width: "100%",
            padding: "1em",
            boxSizing: "border-box",
          }}
        >
          <TextField
            disabled={loading}
            sx={{ marginRight: "2em", flex: 0.5 }}
            value={name}
            label={t(prepareTranslation("Group name")) + "*"}
            variant="standard"
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            disabled={loading}
            sx={{ flex: 1 }}
            value={description}
            label={t(prepareTranslation("Description"))}
            variant="standard"
            onChange={(e) => setDescription(e.target.value)}
          />
          {action === "update" && currentGroup != null && (
            <DeleteButton
              onClick={() => {
                setConfirmMessage("Delete group");
                setConfirmAction(() => (setLoading) => {
                  setLoading(true);
                  deleteUserGroup(currentGroup.id)
                    .then((res) => {
                      dispatch(
                        invokeSnackMessage({
                          message: "Group successfully deleted",
                          isError: false,
                        })
                      );
                    })
                    .finally(() => {
                      dispatch(getGroups(groupsFilter)).then(() => {
                        setLoading(false);
                        setConfirmDialogOpen(false);
                        setOpen(false);
                      });
                    });
                });
                setConfirmDialogOpen(true);
              }}
              buttonstyle={{ marginLeft: "2em" }}
              buttonText="Delete group"
            />
          )}
        </Box>
      </StyledDialogContent>
      <DialogActions>
        <Button
          disabled={!mandatoryFieldsFilled() || loading}
          onClick={action === "update" ? groupUpdate : groupAdd}
        >
          {t(prepareTranslation(action === "update" ? "Save" : "Add"))}
        </Button>
        <Button disabled={loading} onClick={handleDialogClose}>
          {t(prepareTranslation("Close"))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupDialog;
