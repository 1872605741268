import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/config";

const initialState = {
  groupsData: [],
  groupsFilter: { pageNumber: 1, pageSize: 10 },
  groupsLoading: false,
  groupsError: "",
  groupsTotal: 0,
};

export const getGroups = createAsyncThunk(
  "groups/getgroups",
  (filter, { rejectWithValue }) =>
    axiosInstance
      .post("UserGroups/search", filter)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (!err?.response?.data) rejectWithValue("Error");
        return rejectWithValue(err.response.data);
      })
);

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    updateGroupsFilter: (state, action) => {
      state.groupsFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroups.pending, (state, action) => {
      state.groupsLoading = true;
    });
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.groupsData = action.payload.list;
      state.groupsError = "";
      state.groupsLoading = false;
      state.groupsTotal = action.payload.totalCount;
    });
    builder.addCase(getGroups.rejected, (state, action) => {
      state.groupsData = [];
      state.groupsError = action.payload;
      state.groupsLoading = false;
      state.groupsTotal = 0;
    });
  },
});

export default groupsSlice.reducer;
export const { updateGroupsFilter } = groupsSlice.actions;
