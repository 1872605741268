import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FlexColumn, FlexRow } from "../sharedComponents";
import { prepareTranslation, readNameFromProfile } from "../../plugins/helper";
import SpokeOutlinedIcon from "@mui/icons-material/SpokeOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useTheme } from "@emotion/react";
import { invokeSnackMessage } from "../../redux/features/coreSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const ProfileComponent = ({
  profile,
  updateFunction,
  updateSuccessFunction,
  deleteFunction,
  deleteSuccessFunction,
  navigateLink,
  canDelete = true,
  isReadOnlyProfile = false,
  hasPrivacySettings = false,
}) => {
  const dispatch = useDispatch();

  const mapPrivacy = (isPrivate) => {
    switch (isPrivate) {
      case true:
        return "private";
      case false:
        return "public";
      default:
        return "friendsOnly";
    }
  };

  useEffect(() => {
    if (profile?.id != null) {
      setUsername(profile?.name || "");
      setProfileDescription(profile?.description || "");
      setPrivacy(mapPrivacy(profile?.isPrivate));
      setImage(profile?.picture);
    }
  }, [profile]);
  const navigate = useNavigate();
  const [privacy, setPrivacy] = useState("none");
  const [username, setUsername] = useState("");
  const [profileDescription, setProfileDescription] = useState("");
  const [imageChanged, setImageChanged] = useState(false);
  const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const theme = useTheme();
  const fileRef = useRef();

  const inputWidth = { mobile: "80%", tablet: "80%", desktop: "80%" };

  const manageButtonsSizes = { mobile: "0.7em", tablet: "0.9em" };

  const descriptionFieldHeight = "17.8vh";

  const avatarBoxDimensions = {
    mobile: "150px",
    tablet: "180px",
    desktop: "180px",
  };
  const avatarDimensions = {
    mobile: "90px",
    tablet: "120px",
    desktop: "120px",
  };

  const { t } = useTranslation();

  const onProfileUpdate = async () => {
    const formData = new FormData();
    formData.append("backgroundColourId", profile.backgroundColourId || 1);
    if (profile?.userId != null) formData.append("userId", profile.userId);
    formData.append("id", profile.id);
    formData.append("description", profileDescription);
    formData.append("name", username);
    formData.append(
      "isPrivate",
      privacy === "private" ? true : privacy === "public" ? false : ""
    );
    if (imageChanged) {
      formData.append("picture", image, imageName);
      formData.append("changePicture", true);
    } else formData.append("changePicture", false);

    updateFunction(formData, dispatch).then((_res) => {
      dispatch(
        invokeSnackMessage({
          message: "Successful profile update",
          isError: false,
        })
      );
      updateSuccessFunction();
    });
  };

  const onProfileDelete = () => {
    setDeleteLoading(true);
    deleteFunction(profile.id)
      .then(() => {
        deleteSuccessFunction();
      })
      .finally(() => setDeleteLoading(false));
  };

  const handleFileSelect = (event) => {
    imageCompression(event.target.files[0], {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1920,
    }).then((res) => {
      setImageChanged(true);
      setTempImage(URL.createObjectURL(res));
      setImage(res);
    });
    setImageName(event.target.files[0].name);
  };

  return profile?.id != null ? (
    <FlexColumn style={{ alignItems: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
          flex: 1,
          minHeight: avatarBoxDimensions,
          maxHeight: avatarBoxDimensions,
          marginTop: { mobile: "22px", tablet: "32px", desktop: "32px" },
        }}
      >
        <Box
          sx={{
            width: avatarDimensions,
            height: avatarDimensions,
            marginBottom: "0.8em",
            cursor: "pointer",
            "&:hover": {
              "& .MuiSvgIcon-root": {
                opacity: 1,
              },
              "& .MuiAvatar-root": {
                opacity: 0.3,
              },
            },
          }}
          onClick={() => {
            fileRef.current.click();
          }}
        >
          <Avatar
            alt="User avatar"
            src={imageChanged ? tempImage : profile?.picture}
            sx={{
              width: avatarDimensions,
              height: avatarDimensions,
              "& .MuiAvatar-img": {
                objectFit: "cover",
              },
            }}
          />

          <AddCircleOutlineOutlinedIcon
            sx={{
              opacity: 0,
              position: "relative",
              top: {
                mobile: `calc(-30px - 1.2em)`,
                tablet: `calc(-45px - 1.2em)`,
              },
              left: {
                mobile: `calc(34px)`,
                tablet: `calc(47px)`,
              },
              color: theme.palette.primary.main,
            }}
          />
        </Box>

        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          {readNameFromProfile(profile)}
        </Typography>
      </Box>
      {hasPrivacySettings && (
        <FlexRow
          style={{ justifyContent: "center", flex: 0, maxHeight: "100px" }}
        >
          <FormControl>
            <FormLabel
              sx={{
                selfAlign: "center",
                textAlign: "center",
                marginBottom: "10px",
                fontSize: { mobile: "13px", tablet: "15px" },
              }}
              id="demo-row-radio-buttons-group-label"
            >
              {t(prepareTranslation("Privacy"))}
            </FormLabel>
            <RadioGroup
              size="small"
              row
              value={privacy}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => setPrivacy(e.target.value)}
              sx={{
                selfAlign: "center",
                "& .MuiFormControlLabel-label": {
                  fontSize: { mobile: "12px", tablet: "14px" },
                },
              }}
            >
              <FormControlLabel
                value="private"
                control={<Radio size="small" />}
                label={t(prepareTranslation("Private"))}
              />
              <FormControlLabel
                value="public"
                control={<Radio size="small" />}
                label={t(prepareTranslation("Public"))}
              />
              <FormControlLabel
                sx={{ marginRight: 0 }}
                value="friendsOnly"
                control={<Radio size="small" />}
                label={t(prepareTranslation("Friends only"))}
              />
            </RadioGroup>
          </FormControl>
        </FlexRow>
      )}
      <FlexColumn
        style={{
          alignItems: "center",
          marginTop: "1em",
          width: "100%",
          flex: 0,
        }}
      >
        <TextField
          size="small"
          id="username"
          label={t(prepareTranslation("Name and surname"))}
          variant="outlined"
          sx={{ width: inputWidth }}
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />

        <TextField
          multiline
          sx={{
            maxHeight: descriptionFieldHeight,
            ".MuiInputBase-root": {
              height: descriptionFieldHeight,
              ".MuiInputBase-input": {
                height: `calc(${descriptionFieldHeight} - 10px) !important`,
              },
            },
            marginTop: "1em",
            width: inputWidth,
          }}
          size="small"
          id="profile-description"
          label={t(prepareTranslation("Profile description"))}
          variant="outlined"
          value={profileDescription}
          onChange={(event) => setProfileDescription(event.target.value)}
        />
      </FlexColumn>
      {(!isReadOnlyProfile || canDelete) && (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            maxHeight: "40px",

            width: inputWidth,
            marginTop: "1em",
            justifyContent:
              !canDelete || isReadOnlyProfile
                ? "space-around"
                : "space-between",
          }}
        >
          {!isReadOnlyProfile && (
            <Button
              variant="outlined"
              onClick={() => {
                navigate(navigateLink);
              }}
              sx={{
                height: "30px",
                width: "fit-content",
                color: "black",
                background: "#444444",
                borderColor: "black",
                textTransform: "none",
                "&:hover": {
                  background: "#918f8f",
                  borderColor: "black",
                  textTransform: "none",
                },
              }}
            >
              <SpokeOutlinedIcon
                sx={{
                  width: manageButtonsSizes,
                  height: manageButtonsSizes,
                  marginRight: "0.2em",
                  marginLeft: "-0.5em",
                  color: "white",
                }}
              />
              <Typography
                sx={{
                  fontSize: { mobile: "11px", tablet: "13px", desktop: "13px" },
                  color: "white",
                }}
              >
                {t(prepareTranslation("Social networks"))}
              </Typography>
            </Button>
          )}

          {canDelete && (
            <Button
              variant="outlined"
              sx={{
                height: "30px",
                width: "fit-content",
                color: "#750000",
                background: "#9405054d",
                textTransform: "none",
                ":hover": {
                  color: "#901e1e",
                  background: "#9405051c",
                },
              }}
              onClick={() => setDeleteDrawerOpen(true)}
            >
              <DeleteOutlinedIcon
                sx={{
                  width: manageButtonsSizes,
                  height: manageButtonsSizes,
                  marginRight: "0.2em",
                  marginLeft: "-0.5em",
                }}
              />
              <Typography
                sx={{
                  fontSize: { mobile: "11px", tablet: "13px", desktop: "13px" },
                }}
              >
                {t(prepareTranslation("Delete profile"))}
              </Typography>
            </Button>
          )}
        </Box>
      )}
      <FlexRow style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          color="alternate"
          sx={{
            height: "30px",
            minWidth: "150px",
            marginTop: "1.5em",
          }}
          onClick={onProfileUpdate}
        >
          <SaveOutlinedIcon
            sx={{
              width: "0.9em",
              height: "0.9em",
              marginRight: "0.2em",
              marginLeft: "-0.2em",
              color: "white",
            }}
          />
          <Typography sx={{ color: "white" }}>
            {t(prepareTranslation("Save"))}
          </Typography>
        </Button>
      </FlexRow>
      <input
        onClick={(e) => (e.target.value = null)}
        ref={fileRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileSelect}
        accept="image/*"
      />

      <Drawer
        anchor={"bottom"}
        open={deleteDrawerOpen}
        onClose={() => setDeleteDrawerOpen(false)}
        PaperProps={{
          sx: {
            margin: "auto",
            width: { mobile: "100%", tablet: "580px", desktop: "800px" },
            height: "em",
          },
        }}
      >
        <FlexColumn
          style={{
            padding: "1em",
            justifyContent: "space-between",
            alignItems: "center",
            width: inputWidth,
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: "1em" }}>
            {t(prepareTranslation("Delete profile"))}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: { mobile: "300px", tablet: "fit-content" },
              marginBottom: "0.4em",
            }}
          >
            {t(
              prepareTranslation("Are you sure that you want to delete profile")
            ) + "?"}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              width: {
                mobile: "300px",
                tablet: "fit-content",
                fontWeight: "bold",
              },
              marginBottom: "1.8em",
            }}
          >
            {t(prepareTranslation("Attention: This action is permanent")) + "!"}
          </Typography>

          <Button
            variant="contained"
            disabled={profile?.id == null}
            onClick={onProfileDelete}
            sx={{
              ...theme.palette.deleteButton,
              width: inputWidth,
              height: "48px",
              marginBottom: "1.5em",
            }}
          >
            {t(prepareTranslation("Delete"))}
            {deleteLoading && (
              <CircularProgress
                size={"2em"}
                sx={{
                  color: "white",
                  marginLeft: "1em",
                }}
              />
            )}
          </Button>
        </FlexColumn>
      </Drawer>
    </FlexColumn>
  ) : null;
};

export default ProfileComponent;
