import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";
import ba from "./translations/ba.json";
import mk from "./translations/mk.json";
import it from "./translations/it.json";
import fr from "./translations/fr.json";
import es from "./translations/es.json";
import sr from "./translations/sr.json";
import de from "./translations/de.json";

const resources = {
  en: { translation: en },
  ba: { translation: ba },
  mk: { translation: mk },
  it: { translation: it },
  fr: { translation: fr },
  es: { translation: es },
  sr: { translation: sr },
  de: { translation: de },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: process.env.REACT_APP_LANGUAGE_CODE || 'en', // Default to English if not set
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export const changeLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

export default i18n;
