import { TableContainer, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const StyledTableCell = styled(TableCell)(({ theme, headercolor }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: headercolor || "black",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(
  ({ theme, oddrowcolor, hoverrowcolor, clickable }) => ({
    cursor: clickable ? "pointer" : "default",
    "&:nth-of-type(odd)": {
      backgroundColor: oddrowcolor || "gray",
    },
    "&:hover": clickable
      ? {
          backgroundColor: hoverrowcolor || "gray",
        }
      : {},
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  })
);

export const StyledTableContainer = styled(TableContainer)(
  ({ theme, headercolor }) => ({
    "::-webkit-scrollbar": {
      width: "5px",
      height: "6px",
    },

    /* Track */
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    /* Handle */
    "::-webkit-scrollbar-thumb": {
      background: headercolor,
    },

    /* Handle on hover */
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  })
);
