import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/config";

const initialState = {
  socialNetworksData: [],
  socialNetworksFilter: { pageNumber: 1, pageSize: 10 },
  socialNetworksLoading: false,
  socialNetworksError: "",
  socialNetworksTotal: 0,
};

export const getSocialNetworks = createAsyncThunk(
  "socialNetworks/getSocialNetworks",
  (filter, { rejectWithValue }) =>
    axiosInstance
      .post("SocialNetworks/search", filter)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (!err?.response?.data) rejectWithValue("Error");
        return rejectWithValue(err.response.data);
      })
);

const socialNetworksSlice = createSlice({
  name: "socialNetworks",
  initialState,
  reducers: {
    updateSocialNetworksFilter: (state, action) => {
      state.socialNetworksFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSocialNetworks.pending, (state, action) => {
      state.socialNetworksLoading = true;
    });
    builder.addCase(getSocialNetworks.fulfilled, (state, action) => {
      state.socialNetworksData = action.payload.list;
      state.socialNetworksError = "";
      state.socialNetworksLoading = false;
      state.socialNetworksTotal = action.payload.totalCount;
    });
    builder.addCase(getSocialNetworks.rejected, (state, action) => {
      state.socialNetworksData = [];
      state.socialNetworksError = action.payload;
      state.socialNetworksLoading = false;
      state.socialNetworksTotal = 0;
    });
  },
});

export default socialNetworksSlice.reducer;
export const { updateSocialNetworksFilter } = socialNetworksSlice.actions;
