import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { updateLanguagePreference } from '../../redux/features/authSlice';

const flags = {
  ba: '🇧🇦',  // Bosnia and Herzegovina
  mk: '🇲🇰',  // North Macedonia
  en: '🇬🇧',  // United Kingdom (for English)
  it: '🇮🇹',  // Italy
  fr: '🇫🇷',  // France (for French)
  es: '🇪🇸',  // Spain (for Spanish)
  sr: '🇷🇸',  // Serbia (for Serbian)
  de: '🇩🇪',  // Germany (for German)
};

const LanguageSwitcherVertical = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const languagePreference = useSelector(state => state.auth.languagePreference);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(updateLanguagePreference(lang));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: 2, mt: 1 }}>
      {Object.entries(flags).map(([lang, flag]) => (
        <Button
          key={lang}
          onClick={() => changeLanguage(lang)}
          sx={{
            minWidth: 'auto',
            p: 0.5,
            color: 'inherit',
            opacity: languagePreference === lang ? 1 : 0.7,
            '&:hover': { opacity: 0.9 },
          }}
        >
          {flag} {lang.toUpperCase()}
        </Button>
      ))}
    </Box>
  );
};

export default LanguageSwitcherVertical;