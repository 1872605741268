import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { calculateRedirectRoute } from ".";
import { getMe } from "../api/tokenService";
import { BASIC_ROLE, checkUserRole, isLoggedIn } from "../plugins/helper";
import { logout } from "../redux/features/authSlice";
import { invokeSnackMessage } from "../redux/features/coreSlice";

const PrivateRoute = ({
  nonLoggedIn = false,
  children,
  roleRoute = BASIC_ROLE,
}) => {
  const { me } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  let authCondition = isLoggedIn();
  if (!authCondition && getMe() != null) {
    dispatch(logout());
    dispatch(
      invokeSnackMessage({
        message: "Unfamiliar user role",
        isError: true,
      })
    );
  }

  //trying to access sites that require non-logged in user
  if (nonLoggedIn) {
    return !authCondition ? (
      children
    ) : (
      <Navigate to={calculateRedirectRoute(me?.role)} />
    );
  }

  //trying to access sites that require logged in user but user is not logged in
  if (!authCondition) return <Navigate to={"/login"} />;

  //accesing sites by logged in user
  return checkUserRole(me?.role, roleRoute) ? (
    children
  ) : (
    <Navigate to={calculateRedirectRoute(me?.role)} />
  );
};

export default PrivateRoute;
