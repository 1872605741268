const columns = [
  { label: "Group name", align: "left", id: "name", width: "180px" },
  {
    label: "Description",
    align: "center",
    id: "description",
    width: "130px",
  },
];

export default columns;
