import { Box, DialogContent, Grid, List, Tab } from "@mui/material";
import { styled } from "@mui/system";

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  "::-webkit-scrollbar": {
    width: "5px",
    height: "6px",
  },

  /* Track */
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },

  /* Handle */
  "::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
  },

  /* Handle on hover */
  "::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
}));

export const FlexRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  "::-webkit-scrollbar": {
    width: "5px",
    height: "6px",
  },

  /* Track */
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },

  /* Handle */
  "::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
  },

  /* Handle on hover */
  "::-webkit-scrollbar-thumb:hover": {
    background: "#918e66",
  },
}));

export const StyledLink = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
  fontWeight: 600,
  "&:hover": {
    color: theme.palette.primary.hover,
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  [theme.breakpoints.only("tablet")]: {
    fontSize: "0.78rem",
  },

  color: "white",
  "&.Mui-selected": {
    color: "white",
    background: "#ffffff29",
  },
}));

export const FlexColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  "::-webkit-scrollbar": {
    width: "5px",
    height: "6px",
  },

  /* Track */
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },

  /* Handle */
  "::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
  },

  /* Handle on hover */
  "::-webkit-scrollbar-thumb:hover": {
    background: "#918e66",
  },
}));

export const StyledList = styled(List)(({ theme }) => ({
  "::-webkit-scrollbar": {
    width: "5px",
    height: "6px",
  },

  /* Track */
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },

  /* Handle */
  "::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
  },

  /* Handle on hover */
  "::-webkit-scrollbar-thumb:hover": {
    background: "#918e66",
  },
  overflowY: "auto",
  overflowX: "hidden",
  height: "100%",
  paddingRight: "2em",
  width: "100%",
  boxSizing: "border-box",
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  "::-webkit-scrollbar": {
    width: "5px",
    height: "6px",
  },

  /* Track */
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },

  /* Handle */
  "::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
  },

  /* Handle on hover */
  "::-webkit-scrollbar-thumb:hover": {
    background: "#918e66",
  },
}));
