import { getMe } from "../api/tokenService";
import i18n from "../translator/i18n";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import moment from "moment";
import {
  FlexColumn,
  FlexRow,
  StyledLink,
} from "../components/sharedComponents";
import { Avatar, Tooltip, Typography } from "@mui/material";

export const validToken = () => {
  let userData = getMe();

  return userData ? Date.now() < userData.exp * 1000 : false;
};

export const createElementFromVariable = (el, props) => {
  let Element = el;
  return <Element {...props} />;
};

export const mapToFormDataIfExists = (
  t,
  title,
  labelArray,
  dataArray,
  config
) => {
  const result = [];
  labelArray.forEach((label, index) => {
    if (
      dataArray[index].data == null ||
      ((dataArray[index].type === "textArray" ||
        dataArray[index].type === "newlineArray" ||
        dataArray[index].type === "string") &&
        !dataArray[index].data.length)
    )
      return;
    result.push(
      <FlexRow
        style={{
          alignItems: "center",
          padding: "0.3em 0 0.3em 0",
          flex: 0,
          marginBottom: dataArray[index].marginBottom || 0,
          marginTop: dataArray[index].marginTop || 0,
        }}
        key={index}
      >
        {label.length > 0 && (
          <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
            {t(prepareTranslation(label)) + ":"}
          </Typography>
        )}
        {dataArray[index]?.type === "newlineArray" ? (
          <FlexRow style={{ padding: dataArray[index].config?.padding || 0 }}>
            {dataArray[index].numbered && (
              <FlexColumn
                style={{
                  flex: 0.1,
                  marginRight: "0.45em",
                  marginLeft: "0.4em",
                }}
              >
                {dataArray[index].numbers?.map((el, dataIndex) => (
                  <FlexRow
                    key={dataIndex}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "0.1em",
                    }}
                  >
                    <Typography
                      type="subtitle1"
                      style={{
                        fontWeight: 600,
                        fontSize:
                          dataArray[index]?.config?.fontSize || "inherit",
                      }}
                    >
                      {el + ". "}
                    </Typography>
                  </FlexRow>
                ))}
              </FlexColumn>
            )}
            <FlexColumn style={{ flex: 0.9 }}>
              {dataArray[index].data?.map((el, dataIndex) => (
                <FlexRow key={-dataIndex} style={{ alignItems: "center" }}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: dataArray[index]?.config?.fontSize || "inherit",
                    }}
                  >
                    {mapField(
                      el.data,
                      dataArray[index].subType || "string",
                      0,
                      el.linkFunction,
                      dataArray[index].config
                    )}
                  </Typography>
                </FlexRow>
              ))}
            </FlexColumn>
          </FlexRow>
        ) : (
          <Typography
            variant="subtitle1"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "0.3em",
            }}
          >
            {mapField(
              dataArray[index].data,
              dataArray[index].type,
              dataArray[index].cropLength || 0,
              dataArray[index].linkFunction || null,
              dataArray[index].config
            )}
          </Typography>
        )}
      </FlexRow>
    );
  });
  return result.length ? (
    <FlexColumn
      style={{
        maxWidth: config?.mainWidth || "100%",
        padding: config?.mainPadding != null ? config.mainPadding : "1.5em",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6">{t(prepareTranslation(title))}</Typography>
      {result}
    </FlexColumn>
  ) : null;
};

export const mapField = (
  field,
  fieldType = "basic",
  cropLength = 0,
  linkFunction,
  config
) => {
  if (field == null) return "-";
  if (fieldType === "imageBool") {
    return field != null ? (
      <Avatar
        src={field}
        variant="square"
        alt="Logo"
        sx={{ margin: "auto", maxWidth: "40px", maxHeight: "40px" }}
      />
    ) : (
      <ClearOutlinedIcon style={{ color: "red", marginBottom: "-0.03em" }} />
    );
  }
  if (fieldType === "boolean")
    return field ? (
      <DoneOutlinedIcon style={{ color: "green", marginBottom: "-0.12em" }} />
    ) : (
      <ClearOutlinedIcon style={{ color: "red", marginBottom: "-0.03em" }} />
    );
  else if (fieldType === "date") {
    return field
      ? moment(field).format(config?.format || "DD.MM.YYYY HH:mm:ss")
      : "-";
  } else if (fieldType === "textArray") {
    return field.join(", ");
  } else if (fieldType === "link") {
    return <StyledLink onClick={linkFunction}>{field}</StyledLink>;
  } else {
    if (field == null) return "-";
    field = "" + field;
    return field.length > 0 ? (
      cropLength != null && cropLength !== 0 && field.length > cropLength ? (
        <Tooltip title={field}>
          <span style={{ cursor: "default" }}>
            {field.slice(0, cropLength) + "..."}
          </span>
        </Tooltip>
      ) : (
        field
      )
    ) : (
      "-"
    );
  }
};

export const valueHasSubstringInArray = (value, array) =>
  array.filter((el) => value.includes(el))?.length > 0;

export const checkUserRole = (userRole, roleName) => {
  if (!userRole || !(userRole?.length > 0)) return false;
  if (typeof userRole === "object") return userRole?.includes(roleName); // token sends array if multiple and string if one (it should be fixed)
  return userRole === roleName;
};

const snakeCase = (string) => {
  return string
    .replace(/\W+/g, " ")
    .toLowerCase()
    .split(/ |\B(?=[A-Z])/)
    .join("_");
};

export const prepareTranslation = (str) => {
  if (i18n.language === "en") return str;
  return snakeCase(str);
};

export const errorParser = (err) => {
  return err?.response?.data?.message || err?.message || "Unknown server error";
};

export const handleOnDragEndBasic = (sourceArray, result, updateFunction) => {
  if (!result.destination) return;
  const items = Array.from(sourceArray);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);
  updateFunction(items);
};

export const readNameFromProfile = (profile) => {
  return profile?.name || i18n.t(prepareTranslation("unknown"));
};
export const isLoggedIn = () => {
  return !isRoleUndefined(getMe()?.role);
};

export const fetchBlob = async (data) => {
  const base64 = await fetch(data);
  const blob = await base64.blob();
  return blob;
};

export const isRoleUndefined = (role) => {
  return ![ADMIN_ROLE, MODERATOR_ROLE, BASIC_ROLE].includes(role);
};

export const ADMIN_ROLE = "Admin";
export const MODERATOR_ROLE = "Moderator";
export const BASIC_ROLE = "Basic";

export const availableRoles = [BASIC_ROLE, MODERATOR_ROLE, ADMIN_ROLE];
