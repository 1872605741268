import React from "react";
import { useTranslation } from "react-i18next";
import { prepareTranslation } from "../../plugins/helper";
import { FlexRow, StyledLink } from "../sharedComponents";

const DocumentsBar = ({ mainstyle }) => {
  const { t } = useTranslation();

  return (
    <FlexRow
      sx={{
        justifyContent: "space-between",
        flex: 0,

        ...mainstyle,
      }}
    >
      <StyledLink
        href={`${process.env.REACT_APP_API}PrivacyPolicy`}
        target="_blank"
        sx={{ fontSize: "13px" }}
      >
        {t(prepareTranslation("Privacy Policy"))}
      </StyledLink>
      <StyledLink
        href={`${process.env.REACT_APP_API}Copyright`}
        target="_blank"
        sx={{ fontSize: "13px" }}
      >
        {`© ${t(prepareTranslation("Copyright"))}`}
      </StyledLink>
    </FlexRow>
  );
};
//"™ ®"

export default DocumentsBar;
