import React, { useEffect, useState } from "react";
import InfoIllustrative from "../../components/IllustrativeComponents/InfoIllustrative";
import ActivationIllustration from "./assets/ActivationIllustration.svg";
import { FlexColumn, FlexRow } from "../../components/sharedComponents";
import { CircularProgress, Link, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { errorParser, prepareTranslation } from "../../plugins/helper";
import { activateViaMailLink } from "../../api/apis";
import ErrorIllustrative from "../../components/IllustrativeComponents/ErrorIllustrative";
import { useTranslation } from "react-i18next";

const Activation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    setError("");
    if (
      searchParams?.get("username") != null &&
      searchParams?.get("token") != null
    ) {
      let userName = searchParams.get("username");
      let token = searchParams.get("token");
      if (!userName?.length || !token?.length) {
        setError("Invalid activation link");
        setLoading(false);
      } else
        activateViaMailLink(userName, token)
          .then(() => {
            setLoading(false);
            setError("");
          })
          .catch((err) => {
            setLoading(false);
            setError(errorParser(err));
          });
    } else {
      setError("Invalid activation link");
      setLoading(false);
    }
  }, [searchParams]);

  return (
    <FlexColumn
      style={{ height: "100%", justifyContent: "center", marginTop: "-3em" }}
    >
      {!loading && error.length === 0 && (
        <InfoIllustrative
          header="Account successfully activated"
          text={
            <span>
              {t(prepareTranslation("User"))}
              {searchParams?.get("username")?.length > 0 && (
                <i style={{ fontWeight: 700 }}>
                  {" " + searchParams?.get("username")}
                </i>
              )}
              {" " + t(prepareTranslation("is now activated"))}
            </span>
          }
          IllustrationComponent={ActivationIllustration}
        />
      )}

      {loading && (
        <FlexRow
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "120px",
          }}
        >
          <CircularProgress style={{ width: "60px", height: "60px" }} />
        </FlexRow>
      )}

      {!loading && error.length > 0 && <ErrorIllustrative error={error} />}

      <Link
        onClick={() => {
          navigate("/login");
        }}
        component="button"
        style={{ marginTop: "3em" }}
      >
        <Typography variant="body2">
          {t(prepareTranslation("Login"))}
        </Typography>
      </Link>
    </FlexColumn>
  );
};

export default Activation;
