import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  Typography,
  Link,
} from "@mui/material";
import {
  FlexColumn,
  FlexRow,
  StyledGrid,
  StyledList,
} from "../sharedComponents";
import {
  handleOnDragEndBasic,
  prepareTranslation,
  readNameFromProfile,
} from "../../plugins/helper";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { useTheme } from "@emotion/react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { updateSocialNetworkOrder } from "../../api/apis";
import FlipMove from "react-flip-move";
import SocialGrid from "./SocialGrid";
import { useTranslation } from "react-i18next";
import { getOwnProfile } from "../../redux/features/profilesSlice";
import { useDispatch } from "react-redux";
import { StyledSocialIconButton } from "../style";
import { useSelector } from "react-redux";

const ProfileWithSocialMedia = ({
  profile,
  isOwnProfile,
  includeReorder,
  includeDirect,
  containerMarginTop = 0,
  banners = null,
}) => {
  const LIST_VIEW = "list";
  const GRID_VIEW = "grid";
  const theme = useTheme();
  const [viewType, setViewType] = useState(LIST_VIEW); //list, grid
  const [dragEnabled, setDragEnabled] = useState(false);
  const [directFirstActive, setDirectFirstActive] = useState(false);
  const [socialNetworks, setSocialNetworks] = useState([]);
  const { appSettings } = useSelector((store) => store.core);
  const dispatch = useDispatch();

  const dragItem = useRef();
  const dragOverItem = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    if (profile?.socialNetworks) {
      setSocialNetworks(profile?.socialNetworks);
    }
  }, [profile]);

  const updateSocialNetworks = (newSocNetwOrder) => {
    setSocialNetworks(newSocNetwOrder);

    updateSocialNetworkOrder(
      newSocNetwOrder.map((el, index) => ({
        order: index,
        socialNetworkId: el.id,
      })),
      profile.id
    ).then((_res) => {
      if (isOwnProfile) {
        dispatch(getOwnProfile());
      }
    });
  };

  const drop = () => {
    const copyListItems = [...socialNetworks];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setSocialNetworks(copyListItems);
    updateSocialNetworkOrder(
      copyListItems.map((el, index) => ({
        order: index,
        socialNetworkId: el.id,
      })),
      profile.id
    );
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    e.preventDefault();
    dragOverItem.current = position;
  };

  const touchMove = (event, position) => {
    var x = event.changedTouches[0].clientX;
    var y = event.changedTouches[0].clientY;
    var draggedOver = document.elementFromPoint(x, y);
    if (!draggedOver.hasAttribute("draggable")) {
      if (draggedOver.parentElement.hasAttribute("draggable"))
        draggedOver = draggedOver.parentElement;
      else draggedOver = null;
    }
    if (draggedOver != null) {
      dragOverItem.current = parseInt(draggedOver.getAttribute("name"));
    }
  };

  const isProfilePrivate = () => {
    const privacyValue = profile?.isPrivate;
    console.log("isPrivate raw value:", privacyValue, "type:", typeof privacyValue);
    
    // If profile is explicitly public (isPrivate === false or 0), allow access
    if (privacyValue === false || privacyValue === 0) {
      return false;
    }
    
    // For all other cases (isPrivate === 1, true, null, undefined, or any other value), 
    // treat as private and only allow if connected
    return true;
  };

  return profile != null ? (
    <FlexColumn style={{ marginTop: containerMarginTop }}>
      <FlexColumn
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          minHeight: "250px",
          maxHeight: "250px",
        }}
      >
        <Avatar
          alt="User avatar"
          src={profile?.picture}
          sx={{
            width: { mobile: "100px", tablet: "100px", desktop: "150px" },
            height: { mobile: "100px", tablet: "100px", desktop: "150px" },
            marginBottom: "0.8em",
          }}
        />

        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          {readNameFromProfile(profile)}
        </Typography>

        <Typography 
          style={{ 
            fontSize: "14px", 
            textAlign: "center", 
            maxWidth: "80%", 
            marginTop: "0.5em",
            color: theme.palette.text.secondary
          }}
        >
          {profile?.description || `${t(prepareTranslation("description"))}: ${t(prepareTranslation("unknown"))}`}
        </Typography>
      </FlexColumn>

      <FlexColumn
        sx={{
          width: "100%",
          background: "#f9f9f9",
          padding: "0.3em 1em",
          maxHeight: `calc(100vh - 250px - 100px - ${
            banners != null ? "60px" : "0px"
          })`,
          boxSizing: "border-box",
        }}
      >
        {isProfilePrivate() ? (
          <Typography 
            style={{ 
              textAlign: "center", 
              padding: "2em",
              color: theme.palette.text.secondary
            }}
          >
            {t(prepareTranslation("profile_private_message"))}
          </Typography>
        ) : (
          <>
            <FlexRow
              name="header"
              id="header"
              style={{
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                height: "40px",
                flex: 0,
              }}
            >
              <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                {t(prepareTranslation("Contact me via"))}
              </Typography>
              <FlexRow style={{ maxWidth: "fit-content" }}>
                <StyledSocialIconButton onClick={() => setViewType(LIST_VIEW)}>
                  <FormatListBulletedOutlinedIcon
                    style={viewType === LIST_VIEW ? { color: "black" } : {}}
                  />
                </StyledSocialIconButton>
                <StyledSocialIconButton onClick={() => setViewType(GRID_VIEW)}>
                  <WindowOutlinedIcon
                    style={viewType === GRID_VIEW ? { color: "black" } : {}}
                  />
                </StyledSocialIconButton>
                {includeReorder && (
                  <StyledSocialIconButton
                    onClick={() => setDragEnabled((dr) => !dr)}
                  >
                    <ChangeCircleOutlinedIcon
                      style={
                        dragEnabled ? { color: theme.palette.button.alternate } : {}
                      }
                    />
                  </StyledSocialIconButton>
                )}

                {includeDirect && appSettings?.direct && (
                  <StyledSocialIconButton
                    onClick={() => setDirectFirstActive((drfa) => !drfa)}
                  >
                    <StarRateRoundedIcon
                      style={{
                        color: directFirstActive ? "#ffd73b" : "#9a9999",
                        width: "1.1em",
                        height: "1.1em",
                      }}
                    />
                  </StyledSocialIconButton>
                )}
              </FlexRow>
            </FlexRow>
            {viewType === LIST_VIEW && (
              <DragDropContext
                onDragEnd={(result) =>
                  handleOnDragEndBasic(socialNetworks, result, updateSocialNetworks)
                }
              >
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <StyledList
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {socialNetworks.map((el, index) => (
                        <Draggable
                          isDragDisabled={!dragEnabled}
                          key={"" + el.id}
                          draggableId={"" + el.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{
                                ...provided.draggablestyle,
                                "&::before": {
                                  width: "9px",
                                  content: '""',
                                  height: "60px",
                                  background:
                                    directFirstActive && index > 0
                                      ? "transparent"
                                      : "#f9f9f9",
                                  top: "50%",
                                  left: 0,
                                  position: "absolute",
                                },
                                "&::after": {
                                  width: "9px",
                                  content: '""',
                                  height: "60px",
                                  background:
                                    directFirstActive && index > 0
                                      ? "transparent"
                                      : "#f9f9f9",
                                  top: "50%",
                                  right: 0,
                                  position: "absolute",
                                },
                                padding: "0.6em 0.8em",
                                margin: "0.6em 0 0.6em 0",
                                marginTop: index === 0 ? "-0.3em" : "0.6em",
                                borderBottom: `2px solid ${
                                  directFirstActive && index === 0
                                    ? theme.palette.primary.main
                                    : "#d4d3d3"
                                }`,
                                borderRadius: "8px",
                                background:
                                  directFirstActive && index > 0
                                    ? "#dedcdc"
                                    : "transparent",
                              }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  variant="square"
                                  src={el.logo}
                                  style={{
                                    cursor: "pointer",
                                    width: "45px",
                                    height: "45px",
                                    marginRight: "1em",
                                    opacity:
                                      directFirstActive && index > 0 ? "0.5" : "1",
                                  }}
                                  onClick={() => {
                                    console.log("El:" + el);
                                    console.log("Href:" + el.href + "    Data:" + el.data);
                                    const url = el.href ? el.href + el.data : el.data;
                                    window.open(url, "_blank");
                                  }}
                                />
                              </ListItemAvatar>
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  fontSize: "15px",
                                  color:
                                    directFirstActive && index > 0
                                      ? "gray"
                                      : "black",
                                }}
                              >
                                {el.name}
                              </Typography>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </StyledList>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            {viewType === GRID_VIEW && (
              <StyledGrid
                alignItems="center"
                justifyContent="center"
                container
                sx={{ overflowY: "auto" }}
              >
                <FlipMove typeName={null}>
                  {socialNetworks.map((el, index) => (
                    <SocialGrid
                      draggable={dragEnabled}
                      onDragStart={(e) => dragStart(e, index)}
                      onDragEnter={(e) => dragEnter(e, index)}
                      onDragOver={(e) => e.preventDefault()}
                      onDragEnd={drop}
                      onTouchStart={(e) => dragStart(e, index)}
                      onTouchMove={(e) => touchMove(e, index)}
                      onTouchEnd={drop}
                      key={el.id}
                      directfirstactive={directFirstActive}
                      el={el}
                      index={index}
                    />
                  ))}
                </FlipMove>
              </StyledGrid>
            )}
          </>
        )}
      </FlexColumn>
      {banners != null && banners.bannersUri && banners.bannersUri.length > 0 && (
        <FlexRow
          sx={{
            width: "100%",
            minHeight: "60px",
            maxHeight: "60px",
            justifyContent: "center",
            background: "#f9f9f9",
            overflow: "hidden",
          }}
        >
          <Link
            href={banners.bannerAdvertisementLinks[0]}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              component="img"
              src={banners.bannersUri[0]}
              alt="Banner"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                maxWidth: "320px",
              }}
            />
          </Link>
        </FlexRow>
      )}
    </FlexColumn>
  ) : null;
};

export default ProfileWithSocialMedia;
