import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/config";

const initialState = {
  ownLoading: false,
  ownProfile: null,
  hasOwnProfile: null,
  ownError: "",
  connections: [],
  connectionsError: "",
  connectionsLoading: false,
};

// Generates pending, fulfilled and rejected action types
export const getOwnProfile = createAsyncThunk(
  "profiles/get-own",
  (_noReq, { rejectWithValue, dispatch }) =>
    axiosInstance
      .get("Profiles/get-own")
      .then((response) => {
        response.data.socialNetworks = response.data.socialNetworks.sort(
          (el1, el2) => el1.order - el2.order
        );
        return response.data;
      })
      .catch((err) => {
        if (!err?.response?.data) throw err;
        return rejectWithValue(err.response.data);
      })
);

export const getConnections = createAsyncThunk(
  "profiles/get-connections",
  (_noReq, { rejectWithValue, dispatch }) =>
    axiosInstance
      .post("Connections/search", {
        pageNumber: 1,
        pageSize: 5000,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (!err?.response?.data) throw err;
        return rejectWithValue(err.response.data);
      })
);

const profilesSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    clearProfileData: (state, action) => {
      state.ownProfile = null;
      state.connections = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOwnProfile.pending, (state) => {
      state.ownLoading = true;
    });
    builder.addCase(getOwnProfile.fulfilled, (state, action) => {
      state.ownLoading = false;
      state.ownProfile = action.payload;
      state.ownError = "";
      state.hasOwnProfile = true;
    });
    builder.addCase(getOwnProfile.rejected, (state, action) => {
      state.ownLoading = false;
      state.ownError = action.payload.message;
      state.ownProfile = null;
      state.hasOwnProfile = false;
    });
    builder.addCase(getConnections.pending, (state, action) => {
      state.connectionsLoading = true;
    });

    builder.addCase(getConnections.fulfilled, (state, action) => {
      state.connectionsLoading = false;
      state.connectionsError = "";
      state.connections = action.payload.list;
    });
    builder.addCase(getConnections.rejected, (state, action) => {
      state.connectionsLoading = false;
      state.connectionsError = action.payload;
      state.connections = [];
    });
  },
});

export default profilesSlice.reducer;
export const { clearProfileData } = profilesSlice.actions;
