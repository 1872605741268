import React from "react";
import InfoIllustrative from "../../components/IllustrativeComponents/InfoIllustrative";
import ResetSuccessfulIllustration from "./assets/ResetSuccessfulIllustration.svg";
import { FlexColumn } from "../../components/sharedComponents";
import { Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { prepareTranslation } from "../../plugins/helper";

const PasswordResetSuccess = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <FlexColumn
      style={{ height: "100%", justifyContent: "center", marginTop: "-3em" }}
    >
      <InfoIllustrative
        header="Password reset successful"
        text={"You can continue to login now"}
        IllustrationComponent={ResetSuccessfulIllustration}
        imageSize="235px"
        imageMargins="0 0 0 -2.7em"
      />

      <Link
        onClick={() => {
          navigate("/login");
        }}
        component="button"
        style={{ marginTop: "3em" }}
      >
        <Typography variant="body2">
          {t(prepareTranslation("Login"))}
        </Typography>
      </Link>
    </FlexColumn>
  );
};

export default PasswordResetSuccess;
