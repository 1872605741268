import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const StyledGrid = styled(Grid)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.not("mobile")]: {
    height: "fit-content",
  },
  [theme.breakpoints.only("mobile")]: {
    height: "100%",
    marginTop: "0.1em",
  },

  overflow: "auto",
  "::-webkit-scrollbar": {
    width: "5px",
    height: "6px",
  },

  /* Track */
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },

  /* Handle */
  "::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
  },

  /* Handle on hover */
  "::-webkit-scrollbar-thumb:hover": {
    background: "#918e66",
  },
}));
