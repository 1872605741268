import { createTheme } from "@mui/material";

const mainColor = "#00aeef";

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      desktop: 1200,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          fontSize: "0.82rem",
          "&.MuiButton-contained": {
            backgroundColor: mainColor,
            color: "white",
            "&:hover": {
              backgroundColor: "#0090c7", // Darker shade for hover
            },
            "&.Mui-disabled": {
              background: "#b5b3b3",
              color: "white",
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: mainColor,
      background: "#f0f8ff", // Light blue background
      hover: "#0090c7", // Darker shade of main color for hover
    },
    secondary: {
      main: "#000000", // Black as secondary color
    },
    alternate: {
      main: "#ffffff", // White as alternate color
      hover: "#f0f0f0", // Light gray for hover
    },
    delete: {
      main: "#ff0000", // Keeping red for delete actions
    },
    mainGradient: {
      background: `linear-gradient(to right, #0090c7, ${mainColor})`,
      color: "white",
    },
    alternateGradient: {
      background: `linear-gradient(to left, #000000, #333333)`,
      color: "white",
      "&:hover": {
        background: `linear-gradient(to left, #000000, #333333)`,
      },
    },
    loader: {
      main: "#b3e5fc", // Light blue for loader
    },
    text: {
      error: "#ff0000", // Keeping red for error text
    },
    deleteButton: {
      color: "white",
      background: "#ff0000",
      ":hover": {
        color: "white",
        background: "#ff0000ab",
      },
    },
    alternateButton: {
      background: `linear-gradient(to left, #000000, #333333)`,
      color: "white",
      ":hover": {
        background: "#00adefab",
        textTransform: "none",
      },
    },
    button: {
      alternate: "#ffffff",
    },
    table: {
      oddRow: "#00adef1a",
      hoverRow: "#00adef47",
      header: "#00adef",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  buttons: {
    disabled: {
      color: "white",
      background: "#b5b3b3",
    },
  },
});

export default theme;