import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/config";

const initialSnackObject = {
  open: false,
  message: "",
  isError: false,
};

const initialState = {
  snackObject: initialSnackObject,
  appSettings: null,
  loggedUserData: null,
  updateAppSettingsLoading: false,
  updateAppSettingsError: "",
};

export const getLoggedUserData = createAsyncThunk(
  "core/loggedUserData",
  (_noReq, { rejectWithValue }) =>
    axiosInstance
      .get("StartingData")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err?.response?.data) throw err;
        return rejectWithValue(err.response.data);
      })
);

export const getAppSettings = createAsyncThunk(
  "core/getAppSettings",
  (_req, { rejectWithValue }) =>
    axiosInstance
      .get("Settings")
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (!err?.response?.data) rejectWithValue("Error");
        return rejectWithValue(err.response.data);
      })
);

export const updateAppSettings = createAsyncThunk(
  "core/updateAppSettings",
  (req, { rejectWithValue }) =>
    axiosInstance
      .put("Settings", req)
      .then((res) => {
        return req;
      })
      .catch((err) => {
        if (!err?.response?.data) rejectWithValue("Error");
        return rejectWithValue(err.response.data);
      })
);

const coreSlice = createSlice({
  name: "core",
  initialState,
  reducers: {
    invokeSnackMessage: (state, action) => {
      state.snackObject = action.payload;
      state.snackObject["open"] = true;
      state.snackObject["noTranslate"] = !!action.payload?.noTranslate;
    },
    resetSnackObject: (state) => {
      state.snackObject.open = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLoggedUserData.fulfilled, (state, action) => {
      state.loggedUserData = action.payload;
    });
    builder.addCase(getAppSettings.fulfilled, (state, action) => {
      state.appSettings = action.payload;
    });
    builder.addCase(getAppSettings.rejected, (state) => {
      state.appSettings = null;
    });
    builder.addCase(updateAppSettings.pending, (state, action) => {
      state.updateAppSettingsLoading = true;
    });
    builder.addCase(updateAppSettings.fulfilled, (state, action) => {
      state.updateAppSettingsLoading = false;
      state.appSettings = action.payload;
    });
    builder.addCase(updateAppSettings.rejected, (state, action) => {
      state.updateAppSettingsError = action.payload;
      state.updateAppSettingsLoading = false;
    });
  },
});

export default coreSlice.reducer;
export const { invokeSnackMessage, resetSnackObject } = coreSlice.actions;
