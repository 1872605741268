const columns = [
  { label: "Name", align: "left", id: "name", width: "180px" },
  {
    label: "Description",
    align: "center",
    id: "description",
    width: "120px",
  },
  {
    label: "Base link",
    align: "center",
    id: "href",
    width: "150px",
  },
  {
    label: "Logo",
    align: "center",
    id: "logo",
    width: "150px",
    type: "imageBool",
  },
];

export default columns;
