import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  useTheme,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../api/apis";
import { FlexColumn } from "../../components/sharedComponents";
import { prepareTranslation } from "../../plugins/helper";
import { invokeSnackMessage } from "../../redux/features/coreSlice";
import ErrorIllustrative from "../../components/IllustrativeComponents/ErrorIllustrative";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputWidth = { mobile: "80vw", tablet: "50vw", desktop: "30vw" };
  const buttonWidth = { mobile: "50vw", tablet: "20vw", desktop: "300px" };

  const { t } = useTranslation();

  const onResetClick = () => {
    if (password?.length > 0 && confirmPass?.length > 0) {
      if (password !== confirmPass) {
        dispatch(
          invokeSnackMessage({
            message: "Passwords don't match",
            isError: true,
          })
        );
      } else {
        setLoading(true);
        resetPassword(
          searchParams?.get("email"),
          searchParams.get("token"),
          password,
          confirmPass
        )
          .then((res) => {
            setLoading(false);
            navigate(`/reset-successful`);
          })
          .catch((err) => {
            console.log("Error", err);
            setLoading(false);
          });
      }
    } else {
      dispatch(
        invokeSnackMessage({
          message: "Some fields are empty",
          isError: true,
        })
      );
    }
  };
  return (
    <>
      {searchParams?.get("email")?.length > 0 &&
      searchParams?.get("token")?.length > 0 ? (
        <FlexColumn
          style={{
            width: "100%",
            maxHeight: "100vh",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-50px",
          }}
        >
          <Typography variant="h6" style={{ marginBottom: "2em" }}>
            {t(prepareTranslation("Password reset"))}
          </Typography>

          <FormControl>
            <InputLabel htmlFor="outlined-adornment-password">
              {t(prepareTranslation("New password"))}
            </InputLabel>
            <OutlinedInput
              id="password"
              label={t(prepareTranslation("New password"))}
              type={showPassword ? "text" : "password"}
              variant="outlined"
              sx={{
                width: inputWidth,
                marginBottom: "1em",
              }}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((showPass) => !showPass)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl>
            <InputLabel htmlFor="outlined-adornment-password">
              {t(prepareTranslation("Confirm new password"))}
            </InputLabel>
            <OutlinedInput
              id="retypePass"
              label={t(prepareTranslation("Confirm new password"))}
              type={showRetypePassword ? "text" : "password"}
              variant="outlined"
              sx={{
                width: inputWidth,
                marginBottom: "1em",
              }}
              value={confirmPass}
              onChange={(event) => setConfirmPass(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowRetypePassword((showPass) => !showPass)
                    }
                    edge="end"
                  >
                    {showRetypePassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Button
            variant="contained"
            onClick={onResetClick}
            sx={{
              ...theme.palette.mainGradient,
              width: buttonWidth,
              minHeight: "40px",
              marginBottom: "1.5em",
              marginTop: "0.5em",
            }}
          >
            {t(prepareTranslation("Reset password"))}
            {loading && (
              <CircularProgress
                size={"2em"}
                sx={{
                  color: theme.palette.loader.main,
                  marginLeft: "1em",
                }}
              />
            )}
          </Button>
          <Box
            sx={{
              display: "flex",
              marginBottom: "1em",
              justifyContent: "center",
              alignItems: "center",
              minWidth: inputWidth,
            }}
          >
            <FlexColumn style={{ flex: 0.5 }}>
              <Link
                onClick={() => {
                  navigate("/login");
                }}
                component="button"
              >
                <Typography variant="body2">
                  {t(prepareTranslation("Login"))}
                </Typography>
              </Link>
            </FlexColumn>
          </Box>
        </FlexColumn>
      ) : (
        <FlexColumn
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-2em",
          }}
        >
          <ErrorIllustrative error="Broken link" />
          <Box
            sx={{
              display: "flex",
              marginTop: "2em",
              justifyContent: "center",
              alignItems: "center",
              minWidth: inputWidth,
            }}
          >
            <FlexColumn style={{ flex: 0.5 }}>
              <Link
                onClick={() => {
                  navigate("/login");
                }}
                component="button"
              >
                <Typography variant="body2">
                  {t(prepareTranslation("Login"))}
                </Typography>
              </Link>
            </FlexColumn>
          </Box>
        </FlexColumn>
      )}
    </>
  );
};

export default ResetPassword;
