import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/config";

const initialState = {
  usersData: [],
  usersFilter: { pageNumber: 1, pageSize: 10 },
  usersLoading: false,
  usersError: "",
  usersTotal: 0,
};

export const getUsers = createAsyncThunk(
  "users/getusers",
  (filter, { rejectWithValue }) =>
    axiosInstance
      .post("Users/search", filter)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (!err?.response?.data) rejectWithValue("Error");
        return rejectWithValue(err.response.data);
      })
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateUsersFilter: (state, action) => {
      state.usersFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state, action) => {
      state.usersLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.usersData = action.payload.list;
      state.usersError = "";
      state.usersLoading = false;
      state.usersTotal = action.payload.totalCount;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.usersData = [];
      state.usersError = action.payload;
      state.usersLoading = false;
      state.usersTotal = 0;
    });
  },
});

export default usersSlice.reducer;
export const { updateUsersFilter } = usersSlice.actions;
