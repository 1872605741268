import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteProfile, updateProfile } from "../../api/apis";
import ProfileComponent from "../../components/ProfileComponent";
import { logout } from "../../redux/features/authSlice";
import { getOwnProfile } from "../../redux/features/profilesSlice";

const Profile = () => {
  const { ownProfile } = useSelector((store) => store.profiles);
  const { appSettings } = useSelector((store) => store.core);
  const dispatch = useDispatch();

  return (
    <ProfileComponent
      profile={ownProfile}
      deleteFunction={deleteProfile}
      updateFunction={updateProfile}
      deleteSuccessFunction={() => {
        dispatch(logout());
      }}
      updateSuccessFunction={() => {
        dispatch(getOwnProfile());
      }}
      navigateLink="/profile/social-networks"
      canDelete={appSettings?.canDelete}
      isReadOnlyProfile={appSettings?.isReadOnlyProfile}
      hasPrivacySettings={appSettings?.hasPrivacySettings}
    />
  );
};

export default Profile;
