import React from "react";
import InfoIllustrative from "../../components/IllustrativeComponents/InfoIllustrative";
import MailIllustration from "./assets/MailIllustration.svg";
import { FlexColumn } from "../../components/sharedComponents";
import { Link, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { prepareTranslation } from "../../plugins/helper";
import { useTranslation } from "react-i18next";

const CheckMail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();
  return (
    <FlexColumn
      style={{ height: "100%", justifyContent: "center", marginTop: "-3em" }}
    >
      <InfoIllustrative
        header="Almost there"
        text={
          <span>
            {t(
              prepareTranslation(
                "Please confirm your registration via e-mail link we have sent to"
              )
            )}
            {searchParams?.get("email")?.length > 0 && (
              <i style={{ fontWeight: 700 }}>
                {" " + searchParams?.get("email")}
              </i>
            )}
          </span>
        }
        IllustrationComponent={MailIllustration}
      />

      <Link
        onClick={() => {
          navigate("/login");
        }}
        component="button"
        style={{ marginTop: "3em" }}
      >
        <Typography variant="body2">
          {t(prepareTranslation("Login"))}
        </Typography>
      </Link>
    </FlexColumn>
  );
};

export default CheckMail;
