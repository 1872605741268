import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { prepareTranslation } from "../../plugins/helper";
import EmptyIllustration from "../../assets/EmptyIllustration.svg";

const InfoIllustrative = ({
  header,
  text,
  IllustrationComponent = EmptyIllustration,
  imageSize = "210px",
  imageMargins = "0 0 0 0",
}) => {
  const { t } = useTranslation();
  const infoTextWidth = { mobile: "80%", tablet: "60%" };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "fit-content",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={IllustrationComponent}
        alt="Info Illustration"
        style={{
          width: imageSize,
          height: imageSize,
          margin: imageMargins,
          marginBottom: "2em",
        }}
      />
      <Box
        sx={{ width: infoTextWidth, display: "flex", justifyContent: "center" }}
      >
        <Typography
          variant="h5"
          style={{
            marginBottom: "0.3em",
            color: "#393939",
            textAlign: "center",
          }}
        >
          {t(prepareTranslation(header))}
        </Typography>
      </Box>
      <Box
        sx={{
          width: infoTextWidth,
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          style={{
            color: "#585858",
            marginTop: "1em",
            maxWidth: "100%",
            whiteSpace: "break-spaces",
            overflow: "hidden",
          }}
        >
          {typeof text === "string" ? t(prepareTranslation(text)) : text}
        </Typography>
      </Box>
    </div>
  );
};

export default InfoIllustrative;
