import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SaveButton from "../../../components/Buttons/SaveButton";
import { FlexColumn, FlexRow } from "../../../components/sharedComponents";
import { prepareTranslation } from "../../../plugins/helper";
import {
  invokeSnackMessage,
  updateAppSettings,
} from "../../../redux/features/coreSlice";
import GeneralSettingsIllustration from "./assets/GeneralSettingsIllustration1.svg";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

const AdminGeneralSettings = () => {
  const { t } = useTranslation();
  const { appSettings, updateAppSettingsLoading } = useSelector(
    (store) => store.core
  );
  const dispatch = useDispatch();
  const settingKeys = Object.keys(appSettings).filter(
    (el) =>
      !el.toLowerCase().includes("logo") &&
      !el.toLowerCase().includes("quantity")
  );
  const [currAppSettings, setCurrAppSettings] = useState(appSettings);

  const changeCurrAppSettings = (objectKey) => {
    setCurrAppSettings((currAppSettings) => ({
      ...currAppSettings,
      [objectKey]: !currAppSettings[objectKey],
    }));
  };

  const saveCurrentSettings = () => {
    dispatch(updateAppSettings(currAppSettings))
      .unwrap()
      .then(() => {
        dispatch(
          invokeSnackMessage({
            message: "Successfully updated general app settings",
            isError: false,
          })
        );
      });
  };

  const actionButtonsDisabled = () => {
    return (
      updateAppSettingsLoading ||
      JSON.stringify(currAppSettings) === JSON.stringify(appSettings)
    );
  };

  const illustrationSize = { mobile: "5em", tablet: "13em", desktop: "21em" };

  return (
    <FlexColumn
      style={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: "1em",
      }}
    >
      <Typography
        variant="h5"
        sx={{ color: "#757575", fontStyle: "italic", marginBottom: "1.5em" }}
      >
        {t(prepareTranslation("General settings"))}
      </Typography>

      <FlexRow
        style={{
          width: "85%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          margin: "0.5em 0 2em 0",
          flex: 0,
        }}
      >
        <FormControl
          component="fieldset"
          variant="standard"
          sx={{ marginRight: "7em" }}
        >
          <FormGroup>
            {settingKeys.map((el) => (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: {
                      mobile: "10px",
                      tablet: "12px",
                      desktop: "14px",
                    },
                  },
                  marginBottom: {
                    mobile: "8px",
                    tablet: "15px",
                    desktop: "5px",
                  },
                }}
                key={el}
                control={
                  <Switch
                    checked={
                      el === "isReadOnlyProfile"
                        ? !currAppSettings[el]
                        : currAppSettings[el]
                    }
                    onChange={() => changeCurrAppSettings(el)}
                  />
                }
                label={t(prepareTranslation(el)) + " ?"}
              />
            ))}
          </FormGroup>
        </FormControl>
        <Box
          component="img"
          src={GeneralSettingsIllustration}
          sx={{
            width: illustrationSize,
            height: illustrationSize,
            objectFit: "contain",
            marginRight: "-3em",
          }}
        />
      </FlexRow>

      <FlexRow
        style={{
          maxHeight: "50px",
          width: "100%",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => setCurrAppSettings(appSettings)}
          disabled={actionButtonsDisabled()}
          sx={{ height: "40px", width: "200px", marginRight: "2em" }}
        >
          <RestoreOutlinedIcon
            sx={{ marginLeft: "-0.2em", marginRight: "0.2em" }}
          />
          {t(prepareTranslation("Cancel changes"))}
        </Button>
        <SaveButton
          disabled={actionButtonsDisabled()}
          width="200px"
          onClick={saveCurrentSettings}
        />
      </FlexRow>
    </FlexColumn>
  );
};

export default AdminGeneralSettings;
