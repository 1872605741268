import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ErrorIllustration from "../../assets/ErrorIllustration.svg";

const ErrorIllustrative = ({ error, containerProps = {} }) => {
  const infoTextWidth = { mobile: "80%", tablet: "60%" };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "fit-content",
        alignItems: "center",
        justifyContent: "center",
        ...containerProps,
      }}
    >
      <img
        src={ErrorIllustration}
        alt="Error Illustration"
        style={{ width: "270px", height: "270px", marginBottom: "1em" }}
      />
      <Box
        sx={{ width: infoTextWidth, display: "flex", justifyContent: "center" }}
      >
        <Typography
          variant="h5"
          style={{ marginBottom: "0.3em", color: "#393939" }}
        >
          Oooops
        </Typography>
      </Box>
      <Box
        sx={{
          width: infoTextWidth,
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          style={{ color: "#585858", marginTop: "1em" }}
        >
          {error}
        </Typography>
      </Box>
    </div>
  );
};

export default ErrorIllustrative;
