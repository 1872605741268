import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteOuterProfile,
  getProfileById,
  updateOuterProfile,
} from "../../../../api/apis";
import ErrorIllustrative from "../../../../components/IllustrativeComponents/ErrorIllustrative";
import Loader from "../../../../components/Loader";
import ProfileComponent from "../../../../components/ProfileComponent";
import { errorParser, prepareTranslation } from "../../../../plugins/helper";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import { tabsHeight, toolbarHeight } from "../../style";

const UserProfile = () => {
  const { profileId } = useParams();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  useEffect(() => {
    if (profileId?.length > 0) {
      setLoading(true);
      getProfileById(profileId)
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => {
          setError(errorParser(err));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError("Invalid link");
      setLoading(false);
    }
  }, [profileId, setLoading, setError]);

  const navigate = useNavigate();

  const { t } = useTranslation();

  return loading ? (
    <Loader />
  ) : error?.length > 0 ? (
    <ErrorIllustrative error={prepareTranslation(error)} />
  ) : profile?.id != null ? (
    <Box
      sx={{
        display: "flex",
        height: `calc(100vh - ${toolbarHeight} - ${tabsHeight} - 100px)`,
        marginTop: "-1em",
      }}
    >
      <Button
        onClick={() => navigate("/adminpanel/users")}
        sx={{
          width: "150px",
          height: "40px",
          marginLeft: "1em",
          position: "fixed",
          top: `calc(${toolbarHeight} + ${tabsHeight} + 100px)`,
          left: "calc(10% + 1em)",
        }}
        variant="outlined"
      >
        <AssignmentReturnOutlinedIcon
          sx={{ marginRight: "0.3em", marginLeft: "-0.3em" }}
        />
        {t(prepareTranslation("Users"))}
      </Button>
      <ProfileComponent
        profile={profile}
        deleteFunction={deleteOuterProfile}
        updateFunction={updateOuterProfile}
        deleteSuccessFunction={() => {
          navigate("/adminpanel/users");
        }}
        updateSuccessFunction={() => {
          getProfileById(profileId).then((res) => setProfile(res.data));
        }}
        navigateLink={`/adminpanel/users/social-networks/${profile?.id}`}
        canDelete
        isReadOnlyProfile={false}
        hasPrivacySettings
      />
    </Box>
  ) : null;
};

export default UserProfile;
