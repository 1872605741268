import React from "react";
import InfoIllustrative from "../../components/IllustrativeComponents/InfoIllustrative";
import { FlexColumn } from "../../components/sharedComponents";
import NotFoundIllustration from "../../assets/NotFoundIllustration.svg";

const NotFound = () => {
  return (
    <FlexColumn
      style={{ height: "100%", justifyContent: "center", marginTop: "-6em" }}
    >
      <InfoIllustrative
        imageSize="300px"
        header="Page not found"
        IllustrationComponent={NotFoundIllustration}
      />
    </FlexColumn>
  );
};

export default NotFound;
