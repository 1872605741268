import React from "react";
import { useSelector } from "react-redux";
import ProfileWithSocialMedia from "../../components/ProfileWithSocialMedia";

const Home = () => {
  const { ownProfile } = useSelector((store) => store.profiles);

  return (
    <ProfileWithSocialMedia
      includeDirect
      includeReorder
      isOwnProfile
      profile={ownProfile}
    />
  );
};

export default Home;
