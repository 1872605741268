import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "./style";
import { useTranslation } from "react-i18next";
import {
  createElementFromVariable,
  mapField,
  prepareTranslation,
} from "../../plugins/helper";
import { TablePagination } from "@mui/material";

const ColoredTable = ({
  tablestyle,
  rows,
  columns,
  headercolor,
  oddrowcolor,
  hoverrowcolor,
  firstcolicon,
  onRowClick,
  page,
  onRowsPerPageChange,
  onPageChange,
  rowsPerPage,
  totalCount,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledTableContainer
        headercolor={headercolor}
        component={Paper}
        sx={tablestyle}
      >
        <Table
          stickyHeader
          sx={{ minWidth: "100%" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {columns.map((col, index) => (
                <StyledTableCell
                  key={col.id}
                  headercolor={headercolor}
                  align={col.align || "center"}
                  sx={{ minWidth: col.width || "150px" }}
                >
                  {t(prepareTranslation(col.label))}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
                onClick={() => onRowClick(row)}
                oddrowcolor={oddrowcolor}
                hoverrowcolor={hoverrowcolor}
                clickable={onRowClick != null ? 1 : 0}
                key={row.id}
              >
                {columns.map((col, index) => (
                  <StyledTableCell
                    key={col.id}
                    headercolor={headercolor}
                    align={col.align || "center"}
                  >
                    {index === 0 &&
                      createElementFromVariable(firstcolicon, {
                        style: {
                          paddingRight: "0.4em",
                          marginBottom: "-0.3em",
                          width: "1.1em",
                          height: "1.1em",
                          marginLeft: "-0.2em",
                        },
                      })}
                    {mapField(
                      col.nestedFrom
                        ? row[col.nestedFrom] != null
                          ? row[col.nestedFrom][col.id]
                          : "-"
                        : row[col.id],
                      col.type
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        sx={{
          width: "100%",
          maxHeight: "40px",
          minHeight: "40px",
          overflow: "hidden",
          selfAlign: "flex-end",
          border: 0,
        }}
        component="div"
        showFirstButton
        showLastButton
        rowsPerPageOptions={[5, 10, 25]}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={t(prepareTranslation("Rows per page"))}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
};

export default ColoredTable;
