import React from "react";

import { useSelector } from "react-redux";
import { getOwnProfile } from "../../redux/features/profilesSlice";

import SocialNetworksComponent from "../../components/SocialNetworksComponent";
import { useDispatch } from "react-redux";

const SocialNetworks = () => {
  const { ownProfile } = useSelector((store) => store.profiles);
  const dispatch = useDispatch();

  return ownProfile != null ? (
    <SocialNetworksComponent
      profile={ownProfile}
      updateSuccessFunction={() => {
        dispatch(getOwnProfile());
      }}
    />
  ) : null;
};

export default SocialNetworks;
