import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  addProfile,
  deleteOuterProfile,
  deleteUser,
  getProfileByUsername,
  updateUser,
} from "../../../api/apis";
import { getUsers } from "../../../redux/features/usersSlice";
import AddButton from "../../../components/Buttons/AddButton";
import DeleteButton from "../../../components/Buttons/DeleteButton";

import UsersIllustration from "./assets/UsersIllustration.svg";
import {
  FlexColumn,
  FlexRow,
  StyledDialogContent,
} from "../../../components/sharedComponents";
import {
  mapToFormDataIfExists,
  prepareTranslation,
} from "../../../plugins/helper";
import { useSelector } from "react-redux";
import { invokeSnackMessage } from "../../../redux/features/coreSlice";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import UserForm from "../../../components/UserForm";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

const UserDialog = ({
  open,
  setOpen,
  currentUser,
  setCurrentUser,
  setConfirmAction,
  setConfirmMessage,
  setConfirmDialogOpen,
}) => {
  const handleDialogClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const { usersFilter } = useSelector((store) => store.users);
  const [newProfileName, setNewProfileName] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser && open) {
      setNewProfileName("");
      setUserProfile(null);
      getProfileByUsername(currentUser?.userName)
        .then((res) => {
          if (res?.data != null) {
            setNewProfileName(res.data.name || "");
          }
          setUserProfile(res?.data != null ? res.data : null);
        })
        .catch((err) => {
          setUserProfile(null);
        });
    }
  }, [currentUser, open]);

  const refetchCurrentUser = () => {
    let currentUserId = currentUser.id;
    dispatch(getUsers(usersFilter))
      .unwrap()
      .then((res) => {
        setCurrentUser(res.list.find((el) => el.id === currentUserId));
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };

  const addUserProfile = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("userId", currentUser.id);
    formData.append("Name", newProfileName);
    //formData.append("BackgroundColourId", 1);
    addProfile(formData)
      .then((res) => {
        dispatch(
          invokeSnackMessage({
            message: "Profile successfully added",
            isError: false,
          })
        );
      })
      .then((_res) => {
        refetchCurrentUser();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return currentUser != null ? (
    <Dialog
      fullWidth
      PaperProps={{
        sx: { maxWidth: { mobile: "90%", tablet: "85%", desktop: "55%" } },
      }}
      open={open}
      onClose={handleDialogClose}
    >
      <StyledDialogContent sx={{ width: "100%", boxSizing: "border-box" }}>
        <FlexColumn style={{ justifyContent: "center", alignItems: "center" }}>
          <Box
            component="img"
            alt="Illustration"
            src={UsersIllustration}
            sx={{
              width: "120px",
              height: "120px",
              marginTop: "-10px",
              objectFit: "contain",
            }}
          />
          <Typography variant="h6" sx={{ fontStyle: "italic" }}>
            {currentUser.userName}
          </Typography>
        </FlexColumn>

        <UserForm
          buttonText="update"
          currentUser={currentUser}
          submitFunction={updateUser}
          formNeedsFilled={false}
          submitSuccessFunction={() => {
            refetchCurrentUser();
          }}
        />

        <FlexColumn style={{ marginBottom: "1.5em" }}>
          <Typography variant="h6" sx={{ fontStyle: "italic" }}>
            {t(prepareTranslation("Profile"))}
          </Typography>
          <Divider flexItem />
          {mapToFormDataIfExists(
            t,
            "",
            ["Owns profile"],
            [{ data: !!userProfile, type: "boolean" }],
            { mainPadding: "1em 0 0 1em" }
          )}

          <FlexRow
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              boxSizing: "border-box",
              padding: "1em",
            }}
          >
            <TextField
              sx={{ flex: 1 }}
              variant="outlined"
              size="small"
              value={newProfileName}
              disabled={userProfile != null}
              onChange={(e) => setNewProfileName(e.target.value)}
              label={t(prepareTranslation("Profile name"))}
            />
            {userProfile == null ? (
              <AddButton
                onClick={addUserProfile}
                buttonstyle={{ marginLeft: "2em" }}
                buttonText="Add profile"
                disabled={newProfileName?.length === 0 || loading}
              />
            ) : (
              <>
                <DeleteButton
                  width="fit-content"
                  onClick={() => {
                    setConfirmMessage("Delete profile");
                    setConfirmAction(() => (setLoading) => {
                      setLoading(true);
                      deleteOuterProfile(userProfile.id)
                        .then((res) => {
                          dispatch(
                            invokeSnackMessage({
                              message: "Profile successfully deleted",
                              isError: false,
                            })
                          );
                          refetchCurrentUser();
                        })
                        .finally(() => {
                          setLoading(false);
                          setConfirmDialogOpen(false);
                        });
                    });
                    setConfirmDialogOpen(true);
                  }}
                  buttonstyle={{ marginLeft: "2em" }}
                  buttonText="Delete profile"
                />

                <Button
                  variant="contained"
                  sx={{
                    height: "40px",
                    marginLeft: "1em",
                    width: "fit-content",
                    background: theme.palette.alternate.main,
                    "&:hover": {
                      background: theme.palette.alternate.hover,
                    },
                  }}
                  onClick={() => {
                    navigate(`profile/${userProfile?.id}`);
                  }}
                >
                  <EditOutlinedIcon
                    sx={{
                      marginRight: "0.2em",
                      marginLeft: "-0.2em",
                    }}
                  />
                  {t(prepareTranslation("Edit profile"))}
                </Button>
              </>
            )}
          </FlexRow>
        </FlexColumn>

        <FlexColumn>
          <Typography variant="h6" sx={{ fontStyle: "italic" }}>
            {t(prepareTranslation("Delete user"))}
          </Typography>
          <Divider flexItem />
          <FlexRow
            style={{
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
              boxSizing: "border-box",
              padding: "1em",
            }}
          >
            <DeleteButton
              onClick={() => {
                setConfirmMessage("Delete user");
                setConfirmAction(() => (setLoading) => {
                  setLoading(true);
                  deleteUser(currentUser.id)
                    .then((res) => {
                      dispatch(
                        invokeSnackMessage({
                          message: "User successfully deleted",
                          isError: false,
                        })
                      );
                      dispatch(getUsers(usersFilter)).then((_res) => {
                        setOpen(false);
                      });
                    })
                    .finally(() => {
                      setLoading(false);
                      setConfirmDialogOpen(false);
                    });
                });
                setConfirmDialogOpen(true);
              }}
              buttonstyle={{ marginTop: "1em" }}
              buttonText="Delete user"
            />
          </FlexRow>
        </FlexColumn>
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>
          {t(prepareTranslation("Close"))}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default UserDialog;
