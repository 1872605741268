import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfoIllustrative from "../../../components/IllustrativeComponents/InfoIllustrative";
import ErrorIllustrative from "../../../components/IllustrativeComponents/ErrorIllustrative";
import { FlexColumn, FlexRow } from "../../../components/sharedComponents";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ColoredTable from "../../../components/Table/ColoredTable";
import columns from "./columns";
import GroupsIllustration from "./assets/GroupsIllustration.svg";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getGroups,
  updateGroupsFilter,
} from "../../../redux/features/groupsSlice";
import Loader from "../../../components/Loader";
import GroupDialog from "./GroupDialog";
import ConfirmationDialog from "../../../components/Dialogs/ConfirmationDialog";
import AddButton from "../../../components/Buttons/AddButton";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { prepareTranslation } from "../../../plugins/helper";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import _ from "underscore";
import { useTheme } from "@emotion/react";

const AdminUserGroups = () => {
  const { groupsData, groupsLoading, groupsError, groupsFilter, groupsTotal } =
    useSelector((store) => store.groups);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [searchValue, setSearchValue] = useState(groupsFilter?.term || "");
  const [action, setAction] = useState("update");
  const theme = useTheme();
  useEffect(() => {
    dispatch(getGroups(groupsFilter));
  }, [groupsFilter, dispatch]);

  const onGroupRowClick = (row) => {
    setCurrentGroup(row);
    setAction("update");
    setGroupDialogOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    dispatch(updateGroupsFilter({ ...groupsFilter, pageNumber: ++newPage }));
  };

  const handleRowsPerPageChange = (event) => {
    dispatch(
      updateGroupsFilter({ ...groupsFilter, pageSize: event.target.value })
    );
  };

  const debouncedFilterSearch = useRef(
    _.debounce((filterValue, filter) => {
      let newFilter = {
        pageNumber: 1,
        pageSize: filter.pageSize,
      };
      if (filterValue?.length > 0) newFilter["term"] = filterValue;
      dispatch(updateGroupsFilter(newFilter));
    }, 500)
  ).current;

  return (
    <>
      <ConfirmationDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        message={confirmMessage}
        confirmAction={confirmAction}
        includeLoader
      />
      <FlexColumn style={{ height: "100%", alignItems: "center" }}>
        <GroupDialog
          setConfirmDialogOpen={setConfirmDialogOpen}
          setConfirmMessage={setConfirmMessage}
          setConfirmAction={setConfirmAction}
          open={groupDialogOpen}
          setOpen={setGroupDialogOpen}
          currentGroup={currentGroup}
          setCurrentGroup={setCurrentGroup}
          action={action}
        />
        <FlexRow
          style={{
            flex: 0,
            width: "100%",
            justifyContent: "space-between",
            boxSizing: "border-box",
            height: "85px",
            padding: "20px 7px 0 7px",
          }}
        >
          <Box
            component="img"
            alt="Illustration"
            src={GroupsIllustration}
            sx={{
              width: "120px",
              height: "120px",
              marginTop: "-30px",
              objectFit: "contain",
            }}
          />
          <TextField
            sx={{ flex: 0.8 }}
            value={searchValue}
            variant="standard"
            onChange={(e) => {
              setSearchValue(e.target.value);
              debouncedFilterSearch(e.target.value, groupsFilter);
            }}
            label={t(prepareTranslation("Group name"))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title={t(prepareTranslation("Refresh data"))}>
            <IconButton
              sx={{
                width: "1.5em",
                height: "1.5em",
                color: theme.palette.primary.main,
                marginTop: "0.5em",
              }}
              onClick={() => {
                dispatch(getGroups(groupsFilter));
              }}
            >
              <CachedOutlinedIcon sx={{ width: "1.3em", height: "1.3em" }} />
            </IconButton>
          </Tooltip>
          <AddButton
            buttonstyle={{
              marginTop: "7px",
            }}
            buttonText="Add group"
            onClick={() => {
              setAction("add");
              setGroupDialogOpen(true);
            }}
          />
        </FlexRow>
        {groupsLoading ? (
          <Loader loaderSize="6em" />
        ) : groupsError?.length > 0 ? (
          <ErrorIllustrative error={groupsError} />
        ) : groupsData?.length > 0 ? (
          <ColoredTable
            tablestyle={{
              width: "100%",
              marginTop: "30px",
              maxHeight: `calc(100% - 85px - 30px - 30px - 20px)`,
              overflowY: "scroll",
            }}
            rows={groupsData}
            columns={columns}
            headercolor="#333333"
            oddrowcolor="#5c5c5c1c"
            hoverrowcolor="#5c5c5c33"
            firstcolicon={PeopleAltOutlinedIcon}
            onRowClick={(row) => onGroupRowClick(row)}
            page={groupsFilter.pageNumber - 1}
            onRowsPerPageChange={handleRowsPerPageChange}
            onPageChange={handlePageChange}
            totalCount={groupsTotal}
            rowsPerPage={groupsFilter.pageSize}
          />
        ) : (
          <FlexRow
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-3em",
              width: "100%",
            }}
          >
            <InfoIllustrative header={t("No results")} imageSize="150px" />
          </FlexRow>
        )}
      </FlexColumn>
    </>
  );
};

export default AdminUserGroups;
