import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { StyledList } from "./style";
import { useTheme } from "@emotion/react";
import {
  getAllSocialNetworks,
  updateOuterProfile,
  updateProfileSocialNetworks,
} from "../../api/apis";
import { prepareTranslation } from "../../plugins/helper";
import { useTranslation } from "react-i18next";
import { FlexColumn, FlexRow } from "../../components/sharedComponents";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { invokeSnackMessage } from "../../redux/features/coreSlice";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

const SocialNetworksComponent = ({
  profile,
  updateSuccessFunction,
  backNavigationLink = "/profile",
  backgroundContainerColor = "white",
  updateEntireProfile = false,
}) => {
  const theme = useTheme();
  const [socialNetworks, setSocialNetworks] = useState([]);
  const [loadingNetworks, setLoadingNetworks] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (profile != null) {
      setLoadingNetworks(true);
      getAllSocialNetworks()
        .then((res) => {
          res.data = res.data.map((el) => {
            if (el.data == null) el["data"] = "";
            return el;
          });
          profile.socialNetworks.forEach((el) => {
            if (el.data) {
              let indexOfData = res.data.findIndex(
                (el2) => el2.name === el.name
              );
              if (indexOfData !== -1) res.data[indexOfData]["data"] = el.data;
            }
          });
          setSocialNetworks(res.data);
        })
        .finally(() => {
          setLoadingNetworks(false);
        });
    }
  }, [dispatch, profile]);

  const updateSocialNetworkData = (data, index) => {
    setSocialNetworks((scNetworks) =>
      Object.assign([], {
        ...scNetworks,
        [index]: { ...scNetworks[index], data: data },
      })
    );
  };

  const updateSocialNetworks = () => {
    if (updateEntireProfile) {
      const formData = new FormData();
      formData.append("backgroundColourId", profile.backgroundColourId || 1);
      //if (profile?.userId != null) formData.append("userId", profile.userId );
      formData.append("userId", "1d739015-9cfb-46a6-888b-6e91af37213b");
      formData.append("id", profile.id);
      formData.append("description", profile.description);
      formData.append("name", profile.name);
      formData.append(
        "isPrivate",
        profile.privacy != null ? profile.privacy : ""
      );
      formData.append("changePicture", false);

      socialNetworks
        .filter((el) => el?.data?.length > 0)
        .forEach((el, index) => {
          formData.append(`socialNetworks[${index}].id`, el.id);
          formData.append(`socialNetworks[${index}].data`, el.data);
          let profileSocialNetwork = profile.socialNetworks.find(
            (el2) => el2.id === el.id
          );
          formData.append(
            `socialNetworks[${index}].order`,
            profileSocialNetwork != null
              ? profileSocialNetwork.order
              : index + 1
          );
        });
      updateOuterProfile(formData).then((res) => {
        dispatch(
          invokeSnackMessage({
            message: "Successfully updated social networks",
            isError: false,
          })
        );
        updateSuccessFunction();
      });
    } else {
      updateProfileSocialNetworks({
        profileId: profile.id,
        socialNetworks: socialNetworks.filter((el) => el?.data?.length > 0),
      }).then((_res) => {
        dispatch(
          invokeSnackMessage({
            message: "Successfully updated social networks",
            isError: false,
          })
        );
        updateSuccessFunction();
      });
    }
  };

  return (
    <FlexColumn style={{ overflow: "hidden", alignItems: "center" }}>
      {!loadingNetworks ? (
        <StyledList
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "80%",
            minHeight: "80%",
            width: "95%",
            boxSizing: "border-box",
            paddingBottom: 0,
            marginTop: "1em",
            marginBottom: "0.5em",
            marginLeft: "-10px",
          }}
        >
          {socialNetworks.map((el, index) => (
            <ListItem
              key={el.id}
              sx={{
                "&::before": {
                  width: "9px",
                  content: '""',
                  height: "60px",
                  background: backgroundContainerColor,
                  top: "16%",
                  left: 0,
                  position: "absolute",
                },
                "&::after": {
                  width: "9px",
                  content: '""',
                  height: "60px",
                  background: backgroundContainerColor,
                  top: "16%",
                  right: 0,
                  position: "absolute",
                },
                padding: "0.6em 0.8em",
                margin: "0.6em 0 0em 0",
                marginTop: index === 0 ? "-0.3em" : 0,
                borderBottom: `2px solid ${
                  el.data?.length > 0 ? theme.palette.primary.main : "#d4d3d3"
                }`,
                borderRadius: "8px",
                background: "transparent",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  variant="square"
                  src={el.logo}
                  style={{
                    cursor: "pointer",
                    width: "40px",
                    height: "40px",
                    marginRight: "1em",
                  }}
                  onClick={() => {
                    if (el.name === "Viber")
                      window.open(el.href + el.data, "_blank");
                    else window.open("https://" + el.data, "_blank");
                  }}
                />
              </ListItemAvatar>

              <TextField
                sx={{ width: "100%" }}
                variant="standard"
                label={el.name}
                InputProps={{ disableUnderline: true }}
                value={socialNetworks[index].data}
                onChange={(e) => {
                  updateSocialNetworkData(e.target.value, index);
                }}
              />
              <Tooltip title={el.description}>
                <InfoOutlinedIcon sx={{ color: theme.palette.primary.main }} />
              </Tooltip>
            </ListItem>
          ))}
        </StyledList>
      ) : (
        <Loader
          containerSize="80%"
          containerstyle={{ marginTop: "1em", marginBottom: "0.5em" }}
        />
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "95%",
          marginLeft: "-15px",
          maxHeight: { mobile: "80px", tablet: "80px", desktop: "80px" },
          minHeight: { mobile: "80px", tablet: "80px", desktop: "80px" },
        }}
      >
        <Button
          onClick={() => navigate(backNavigationLink)}
          sx={{
            width: "150px",
            height: "40px",
            marginLeft: "1em",
          }}
          variant="outlined"
        >
          <AssignmentReturnOutlinedIcon
            sx={{ marginRight: "0.3em", marginLeft: "-0.3em" }}
          />
          {t(prepareTranslation("Profile"))}
        </Button>
        <FlexRow />
        <Button
          variant="contained"
          disabled={profile?.id == null}
          sx={{
            color: "white",
            width: { mobile: "150px", tablet: "250px", desktop: "250px" },
            alignSelf: "center",
            marginLeft: {
              mobile: "-10px",
              tablet: "-20px",
              desktop: "-120px",
            },
          }}
          onClick={updateSocialNetworks}
        >
          <SaveOutlinedIcon
            sx={{ marginRight: "0.5em", marginLeft: "-0.5em" }}
          />
          {t(prepareTranslation("Save"))}
        </Button>
        <FlexRow />
      </Box>
    </FlexColumn>
  );
};

export default SocialNetworksComponent;
