import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getHelpCenterData } from "../../api/apis";
import ErrorIllustrative from "../../components/IllustrativeComponents/ErrorIllustrative";
import Loader from "../../components/Loader";
import {
  FlexColumn,
  FlexRow,
  StyledList,
} from "../../components/sharedComponents";
import { errorParser, prepareTranslation } from "../../plugins/helper";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/system";

const HelpCenter = () => {
  const [helpCenterData, setHelpCenterData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const [openedId, setOpenedId] = useState(null);

  const onHoverListItemColor = "#c8c5c5";
  useEffect(() => {
    setLoading(true);
    getHelpCenterData()
      .then((res) => {
        setHelpCenterData(res.data.list);
      })
      .catch((err) => {
        setError(errorParser(err));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return loading ? (
    <Loader />
  ) : error?.length > 0 ? (
    <ErrorIllustrative error={error} />
  ) : helpCenterData?.length > 0 ? (
    <FlexColumn
      style={{
        width: "100%",
        height: "calc(100vh - 90px)",
        alignItems: "center",
      }}
    >
      <StyledList
        sx={{
          marginTop: "1em",
          maxHeight: "90%",
          padding: "0.8em",
          marginLeft: "-20px",
          width: "95%",
        }}
      >
        {helpCenterData.map((el, index) => (
          <div key={el.id}>
            <ListItemButton
              onClick={() => {
                setOpenedId(openedId === el.id ? null : el.id);
              }}
              disableRipple
              sx={{
                ":hover": {
                  "& .MuiListItemText-root": {
                    color: onHoverListItemColor,
                  },
                  background: "white",
                  ":focus": {
                    background: "white",
                  },
                },
                "&::before": {
                  width: "9px",
                  content: '""',
                  height: "60px",
                  background: "white",
                  top: "50%",
                  left: 0,
                  position: "absolute",
                },
                "&::after": {
                  width: "9px",
                  content: '""',
                  height: "60px",
                  background: "white",
                  top: "50%",
                  right: 0,
                  position: "absolute",
                },
                padding: "0.6em 0.8em",

                marginTop: index === 0 ? "-0.5em" : "0.6em",
                borderBottom: `2px solid #d4d3d3`,
                borderRadius: "8px",
                background: "transparent",
              }}
            >
              <ListItemIcon
                sx={{
                  "&.MuiListItemIcon-root": {
                    maxWidth: "2em",
                    minWidth: "2em",
                  },
                }}
              >
                <HelpOutlineOutlinedIcon
                  sx={{ color: theme.palette.primary.main }}
                />
              </ListItemIcon>
              <ListItemText primary={el.question} />
              {openedId === el.id ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openedId === el.id} timeout="auto" unmountOnExit>
              <ListItem>
                <Typography
                  dangerouslySetInnerHTML={{ __html: el.answer }}
                ></Typography>
              </ListItem>
            </Collapse>
          </div>
        ))}
      </StyledList>
    </FlexColumn>
  ) : (
    <FlexRow
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" sx={{ fontStyle: "italic" }}>
        {t(prepareTranslation("No data"))}
      </Typography>
    </FlexRow>
  );
};

export default HelpCenter;
