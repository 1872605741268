import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfoIllustrative from "../../../components/IllustrativeComponents/InfoIllustrative";
import ErrorIllustrative from "../../../components/IllustrativeComponents/ErrorIllustrative";
import { FlexColumn, FlexRow } from "../../../components/sharedComponents";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ColoredTable from "../../../components/Table/ColoredTable";
import columns from "./columns";
import UsersIllustration from "./assets/UsersIllustration.svg";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getUsers,
  updateUsersFilter,
} from "../../../redux/features/usersSlice";
import Loader from "../../../components/Loader";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { prepareTranslation } from "../../../plugins/helper";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import _ from "underscore";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { useTheme } from "@emotion/react";

const ModeratorUsers = () => {
  const { usersData, usersLoading, usersError, usersFilter, usersTotal } =
    useSelector((store) => store.users);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(usersFilter?.term || "");
  const theme = useTheme();
  useEffect(() => {
    dispatch(getUsers(usersFilter));
  }, [usersFilter, dispatch]);

  const handlePageChange = (event, newPage) => {
    dispatch(updateUsersFilter({ ...usersFilter, pageNumber: ++newPage }));
  };

  const handleRowsPerPageChange = (event) => {
    dispatch(
      updateUsersFilter({ ...usersFilter, pageSize: event.target.value })
    );
  };

  const debouncedFilterSearch = useRef(
    _.debounce((filterValue, filter) => {
      let newFilter = {
        pageNumber: 1,
        pageSize: filter.pageSize,
      };
      if (filterValue?.length > 0) newFilter["term"] = filterValue;
      dispatch(updateUsersFilter(newFilter));
    }, 500)
  ).current;

  return (
    <>
      <FlexColumn style={{ height: "100%", alignItems: "center" }}>
        <FlexRow
          style={{
            flex: 0,
            width: "100%",
            justifyContent: "space-between",
            boxSizing: "border-box",
            height: "85px",
            padding: "20px 7px 0 7px",
          }}
        >
          <Box
            component="img"
            alt="Illustration"
            src={UsersIllustration}
            sx={{
              width: "120px",
              height: "120px",
              marginTop: "-35px",
              objectFit: "contain",
            }}
          />
          <TextField
            sx={{ flex: 0.8 }}
            value={searchValue}
            variant="standard"
            onChange={(e) => {
              setSearchValue(e.target.value);
              debouncedFilterSearch(e.target.value, usersFilter);
            }}
            label={t(prepareTranslation("Username"))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />

          <Tooltip title={t(prepareTranslation("Refresh data"))}>
            <IconButton
              sx={{
                width: "1.5em",
                height: "1.5em",
                color: theme.palette.primary.main,
                marginTop: "0.5em",
              }}
              onClick={() => {
                dispatch(getUsers(usersFilter));
              }}
            >
              <CachedOutlinedIcon sx={{ width: "1.3em", height: "1.3em" }} />
            </IconButton>
          </Tooltip>
        </FlexRow>
        {usersLoading ? (
          <Loader loaderSize="6em" />
        ) : usersError?.length > 0 ? (
          <ErrorIllustrative error={usersError} />
        ) : usersData?.length > 0 ? (
          <ColoredTable
            tablestyle={{
              width: "100%",
              marginTop: "30px",
              maxHeight: `calc(100% - 85px - 30px - 30px - 20px)`,
              overflowY: "scroll",
            }}
            rows={usersData}
            columns={columns}
            headercolor="#831c1a"
            oddrowcolor="#831c1a12"
            hoverrowcolor="#831c1a26"
            firstcolicon={PersonOutlinedIcon}
            page={usersFilter.pageNumber - 1}
            onRowsPerPageChange={handleRowsPerPageChange}
            onPageChange={handlePageChange}
            totalCount={usersTotal}
            rowsPerPage={usersFilter.pageSize}
          />
        ) : (
          <FlexRow
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-3em",
              width: "100%",
            }}
          >
            <InfoIllustrative header={t("No results")} imageSize="150px" />
          </FlexRow>
        )}
      </FlexColumn>
    </>
  );
};

export default ModeratorUsers;
