import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import RoutesList from "./routes";
import "./App.css";
import { ThemeProvider } from "@mui/system";
import theme from "./theme";
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import { injectStore } from "./api/config";
import { changeLanguage } from "./translator/i18n";

injectStore(store);

const AppContent = () => {
  const languagePreference = useSelector(state => state.auth.languagePreference);

  useEffect(() => {
    if (languagePreference) {
      changeLanguage(languagePreference);
    }
  }, [languagePreference]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Layout>
          <RoutesList />
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <AppContent />
    </Provider>
  );
};

export default App;
