import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import UsersIllustration from "./assets/UsersIllustration.svg";
import {
  FlexColumn,
  StyledDialogContent,
} from "../../../components/sharedComponents";
import { prepareTranslation } from "../../../plugins/helper";
import { useDispatch } from "react-redux";
import { invokeSnackMessage } from "../../../redux/features/coreSlice";
import { addUser } from "../../../api/apis";
import { getUsers } from "../../../redux/features/usersSlice";
import { useSelector } from "react-redux";
import UserForm from "../../../components/UserForm";

const RegisterDialog = ({ open, setOpen }) => {
  const handleDialogClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { usersFilter } = useSelector((store) => store.users);

  return (
    <Dialog
      fullWidth
      PaperProps={{ sx: { maxWidth: "50%" } }}
      open={open}
      onClose={handleDialogClose}
    >
      <StyledDialogContent sx={{ width: "100%", boxSizing: "border-box" }}>
        <FlexColumn style={{ justifyContent: "center", alignItems: "center" }}>
          <Box
            component="img"
            alt="Illustration"
            src={UsersIllustration}
            sx={{
              width: "110px",
              height: "110px",
              marginTop: "-10px",
              objectFit: "contain",
            }}
          />
          <Typography variant="h6" sx={{ fontStyle: "italic" }}>
            {t(prepareTranslation("Registration"))}
          </Typography>
        </FlexColumn>

        <UserForm
          submitSuccessFunction={() => {
            dispatch(
              invokeSnackMessage({
                message: "User successfully added. Password sent to user email",
                isError: false,
              })
            );
            dispatch(getUsers(usersFilter));
            setOpen(false);
          }}
          submitFunction={addUser}
          buttonText="Add user"
          formstyle={{ paddingBottom: 0 }}
        />
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>
          {t(prepareTranslation("Close"))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterDialog;
