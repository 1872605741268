import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/config";
import {
  getLocalAccessToken,
  getLocalRefreshToken,
  getMe,
  removeMe,
  setLocalAccessToken,
  setLocalRefreshToken,
  setLanguagePreference,
  getLanguagePreference,
} from "../../api/tokenService";

const initialState = {
  loading: false,
  error: "",
  token: getLocalAccessToken(),
  refreshToken: getLocalRefreshToken(),
  me: getMe(),
  languagePreference: getLanguagePreference() || 'en', // Default to English if not set
};

// Generates pending, fulfilled and rejected action types
export const login = createAsyncThunk(
  "auth/login",
  (loginReq, { getState, rejectWithValue }) => {
    const { auth } = getState();
    const loginData = {
      ...loginReq,
      languagePreference: auth.languagePreference
    };
    return axiosInstance
      .post("Auth/login", loginData)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (!err?.response?.data) rejectWithValue("Error");
        return rejectWithValue(err.response.data);
      });
  }
);


export const updateLanguagePreference = createAsyncThunk(
  "auth/updateLanguagePreference",
  async (language, { dispatch }) => {
    localStorage.setItem("lang", language);
    return language;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.refreshToken = null;
      state.me = null;
      state.languagePreference = 'en'; // Reset to default language
      removeMe();
      // window.location.reload(false);
    },
    refreshToken: (state, action) => {
      state.token = action.payload.auth_token;
      state.refreshToken = action.payload.refresh_token;
      setLocalAccessToken(action.payload.auth_token);
      setLocalRefreshToken(action.payload.refresh_token);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.auth_token;
        state.refreshToken = action.payload.refresh_token;
        state.error = "";
        state.languagePreference = action.payload.languagePreference || state.languagePreference;
        setLocalAccessToken(action.payload.auth_token);
        setLocalRefreshToken(action.payload.refresh_token);
        setLanguagePreference(state.languagePreference);
        state.me = getMe();
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.token = "";
        state.refreshToken = "";
        state.error = action.payload.message;
      })
      .addCase(updateLanguagePreference.fulfilled, (state, action) => {
        state.languagePreference = action.payload;
      });
  },
});

export default authSlice.reducer;
export const { logout, refreshToken } = authSlice.actions;
