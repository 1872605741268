import axios from "axios";
import {
  getLanguagePreference,
  getLocalAccessToken,
  getLocalRefreshToken,
} from "./tokenService";
import { logout, refreshToken } from "../redux/features/authSlice";
import { invokeSnackMessage } from "../redux/features/coreSlice";
import { errorParser } from "../plugins/helper";

let store;

export const injectStore = (_store) => {
  store = _store;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Accept-Language"] = getLanguagePreference();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/Auth/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await axiosInstance.post("/Auth/refresh-token", {
            refreshToken: getLocalRefreshToken(),
          });

          store.dispatch(refreshToken(rs.data));

          return axiosInstance(originalConfig);
        } catch (_error) {
          console.log("Error", _error);
          store.dispatch(logout());
          return Promise.reject(_error);
        }
      }
    }
    if (err.response.status !== 404)
      store.dispatch(
        invokeSnackMessage({
          message: errorParser(err),
          isError: true,
          noTranslate: true,
        })
      );

    return Promise.reject(err);
  }
);

export default axiosInstance;
