export const iconResponsiveWidth = {
  mobile: "0.7em",
  tablet: "1em",
  desktop: "1em",
};

export const actionButtonStyle = {
  width: { mobile: "130px", tablet: "180px", desktop: "180px" },
  padding: 0,
  height: "35px",
  marginLeft: "1em",
  position: "relative",
  left: 0,
  top: "20px",
  fontSize: { mobile: "10px", tablet: "12px", desktop: "12px" },
  display: "flex",
  alignItems: "center",
  borderColor: "#005C98",
  color: "#005C98",
  "&:hover": {
    borderColor: "#005C98",
    background: "#005c980d",
  },
};

export const buttonIconStyle = {
  marginRight: { mobile: "0.1em", tablet: "0.2em", desktop: "0.2em" },
  marginLeft: {
    mobile: "-0.1em",
    tablet: "-0.2em",
    desktop: "-0.2em",
  },

  width: iconResponsiveWidth,
};
