import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfoIllustrative from "../../../components/IllustrativeComponents/InfoIllustrative";
import ErrorIllustrative from "../../../components/IllustrativeComponents/ErrorIllustrative";
import { FlexColumn, FlexRow } from "../../../components/sharedComponents";

import ColoredTable from "../../../components/Table/ColoredTable";
import columns from "./columns";
import SocialNetworksIllustration from "./assets/SocialNetworksIllustration.svg";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getSocialNetworks } from "../../../redux/features/socialNetworksSlice";
import Loader from "../../../components/Loader";
import SocialNetworkDialog from "./SocialNetworkDialog";
import ConfirmationDialog from "../../../components/Dialogs/ConfirmationDialog";
import AddButton from "../../../components/Buttons/AddButton";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { prepareTranslation } from "../../../plugins/helper";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import _ from "underscore";
import { updateSocialNetworksFilter } from "../../../redux/features/socialNetworksSlice";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import LinkIcon from "@mui/icons-material/Link";
import { useTheme } from "@emotion/react";

const AdminSocialNetworks = () => {
  const {
    socialNetworksData,
    socialNetworksLoading,
    socialNetworksError,
    socialNetworksFilter,
    socialNetworksTotal,
  } = useSelector((store) => store.socialNetworks);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [socialNetworkDialogOpen, setSocialNetworkDialogOpen] = useState(false);
  const [currentSocialNetwork, setCurrentSocialNetwork] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [searchValue, setSearchValue] = useState(
    socialNetworksFilter?.term || ""
  );
  const theme = useTheme();
  const [action, setAction] = useState("update");
  useEffect(() => {
    dispatch(getSocialNetworks(socialNetworksFilter));
  }, [socialNetworksFilter, dispatch]);

  const onSocialNetworkRowClick = (row) => {
    setCurrentSocialNetwork(row);
    setAction("update");
    setSocialNetworkDialogOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    dispatch(
      updateSocialNetworksFilter({
        ...socialNetworksFilter,
        pageNumber: ++newPage,
      })
    );
  };

  const handleRowsPerPageChange = (event) => {
    dispatch(
      updateSocialNetworksFilter({
        ...socialNetworksFilter,
        pageSize: event.target.value,
      })
    );
  };

  const debouncedFilterSearch = useRef(
    _.debounce((filterValue, filter) => {
      let newFilter = {
        pageNumber: 1,
        pageSize: filter.pageSize,
      };
      if (filterValue?.length > 0) newFilter["term"] = filterValue;
      dispatch(updateSocialNetworksFilter(newFilter));
    }, 500)
  ).current;

  return (
    <>
      <ConfirmationDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        message={confirmMessage}
        confirmAction={confirmAction}
        includeLoader
      />
      <FlexColumn style={{ height: "100%", alignItems: "center" }}>
        <SocialNetworkDialog
          setConfirmDialogOpen={setConfirmDialogOpen}
          setConfirmMessage={setConfirmMessage}
          setConfirmAction={setConfirmAction}
          open={socialNetworkDialogOpen}
          setOpen={setSocialNetworkDialogOpen}
          currentSocialNetwork={currentSocialNetwork}
          setCurrentSocialNetwork={setCurrentSocialNetwork}
          action={action}
        />
        <FlexRow
          style={{
            flex: 0,
            width: "100%",
            justifyContent: "space-between",
            boxSizing: "border-box",
            height: "85px",
            padding: "20px 7px 0 7px",
          }}
        >
          <Box
            component="img"
            alt="Illustration"
            src={SocialNetworksIllustration}
            sx={{
              width: "130px",
              height: "130px",
              marginTop: "-30px",
              objectFit: "contain",
            }}
          />
          <TextField
            sx={{ flex: 0.8 }}
            value={searchValue}
            variant="standard"
            onChange={(e) => {
              setSearchValue(e.target.value);
              debouncedFilterSearch(e.target.value, socialNetworksFilter);
            }}
            label={t(prepareTranslation("SocialNetwork name"))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title={t(prepareTranslation("Refresh data"))}>
            <IconButton
              sx={{
                width: "1.5em",
                height: "1.5em",
                color: theme.palette.primary.main,
                marginTop: "0.5em",
              }}
              onClick={() => {
                dispatch(getSocialNetworks(socialNetworksFilter));
              }}
            >
              <CachedOutlinedIcon sx={{ width: "1.3em", height: "1.3em" }} />
            </IconButton>
          </Tooltip>
          <AddButton
            buttonstyle={{
              marginTop: "7px",
            }}
            buttonText="Add socialNetwork"
            onClick={() => {
              setAction("add");
              setSocialNetworkDialogOpen(true);
            }}
          />
        </FlexRow>
        {socialNetworksLoading ? (
          <Loader loaderSize="6em" />
        ) : socialNetworksError?.length > 0 ? (
          <ErrorIllustrative error={socialNetworksError} />
        ) : socialNetworksData?.length > 0 ? (
          <ColoredTable
            tablestyle={{
              width: "100%",
              marginTop: "30px",
              maxHeight: `calc(100% - 85px - 30px - 30px - 20px)`,
              overflowY: "scroll",
            }}
            rows={socialNetworksData}
            columns={columns}
            headercolor="#0a3f68"
            oddrowcolor="#0a3f681c"
            hoverrowcolor="#0a3f6833"
            firstcolicon={LinkIcon}
            onRowClick={(row) => onSocialNetworkRowClick(row)}
            page={socialNetworksFilter.pageNumber - 1}
            onRowsPerPageChange={handleRowsPerPageChange}
            onPageChange={handlePageChange}
            totalCount={socialNetworksTotal}
            rowsPerPage={socialNetworksFilter.pageSize}
          />
        ) : (
          <FlexRow
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-3em",
              width: "100%",
            }}
          >
            <InfoIllustrative header={t("No results")} imageSize="150px" />
          </FlexRow>
        )}
      </FlexColumn>
    </>
  );
};

export default AdminSocialNetworks;
