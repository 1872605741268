import { CircularProgress } from "@mui/material";
import React from "react";
import { FlexRow } from "../sharedComponents";

const Loader = ({
  loaderSize = "4em",
  containerSize = "100%",
  containerstyle = {},
  ...props
}) => {
  return (
    <FlexRow
      style={{
        minWidth: containerSize,
        maxWidth: containerSize,
        maxHeight: containerSize,
        minHeight: containerSize,
        justifyContent: "center",
        alignItems: "center",
        ...containerstyle,
      }}
      {...props}
    >
      <CircularProgress style={{ width: loaderSize, height: loaderSize }} />
    </FlexRow>
  );
};

export default Loader;
