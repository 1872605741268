import {
  Avatar,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  FlexColumn,
  FlexRow,
  StyledGrid,
  StyledList,
} from "../../components/sharedComponents";
import { prepareTranslation } from "../../plugins/helper";
import { getConnections } from "../../redux/features/profilesSlice";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import ErrorIllustrative from "../../components/IllustrativeComponents/ErrorIllustrative";
import { StyledSocialIconButton } from "../../components/style";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { deleteConnection } from "../../api/apis";
import { invokeSnackMessage } from "../../redux/features/coreSlice";

const Connections = () => {
  const LIST_VIEW = "list";
  const GRID_VIEW = "grid";
  const { t } = useTranslation();
  const { connections, connectionsLoading, connectionsError } = useSelector(
    (state) => state.profiles
  );
  const inputWidth = { mobile: "80%", tablet: "80%", desktop: "80%" };
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false);
  const [viewType, setViewType] = useState(LIST_VIEW);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConnections());
  }, [dispatch]);

  const createButtonRow = (el) => (
    <FlexRow style={{ justifyContent: "flex-end", alignItems: "center" }}>
      <Tooltip title={t(prepareTranslation("Visit profile"))}>
        <IconButton
          sx={{ width: "1.2em", height: "1.2em" }}
          onClick={() => {
            navigate(`/connections/${el.userName}`);
          }}
        >
          <AssignmentIndOutlinedIcon
            sx={{
              ":hover": { color: theme.palette.alternate.main },
              width: "1em",
              height: "1em",
              color: theme.palette.alternate.main,
            }}
          />
        </IconButton>
      </Tooltip>

      <Tooltip title={t(prepareTranslation("Delete connection"))}>
        <IconButton
          onClick={() => {
            setSelectedUser(el);
            setDeleteDrawerOpen(true);
          }}
          sx={{
            width: "1.2em",
            height: "1.2em",
            marginLeft: "0.3em",
          }}
        >
          <DeleteOutlineOutlinedIcon
            sx={{
              ":hover": { color: theme.palette.delete.main },
              width: "1em",
              height: "1em",
              color: "#a14848",
            }}
          />
        </IconButton>
      </Tooltip>
    </FlexRow>
  );

  const onConnectionDelete = () => {
    setDeleteLoading(true);
    deleteConnection(selectedUser.userId)
      .then((_res) => {
        dispatch(
          invokeSnackMessage({
            message: "Successfully deleted connection",
            isError: false,
          })
        );
        dispatch(getConnections());
      })
      .catch((err) => {
        console.log("Error", err);
      })
      .finally(() => {
        setDeleteLoading(false);
        setDeleteDrawerOpen(false);
        setSelectedUser(null);
      });
  };

  return connectionsLoading ? (
    <Loader containerstyle={{ minHeight: "80%", maxHeight: "80%" }} />
  ) : connectionsError?.length > 0 ? (
    <ErrorIllustrative error={connectionsError} />
  ) : (
    <FlexColumn
      style={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        paddingTop: "1em",
      }}
    >
      <FlexRow
        name="header"
        id="header"
        style={{
          justifyContent: "space-between",
          width: "95%",
          padding: "0 1.8em 0 1em",
          alignItems: "center",
          height: "40px",
          flex: 0,
          boxSizing: "border-box",
        }}
      >
        <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
          {t(prepareTranslation("Select your view"))}
        </Typography>
        <FlexRow style={{ maxWidth: "fit-content" }}>
          <StyledSocialIconButton onClick={() => setViewType(LIST_VIEW)}>
            <FormatListBulletedOutlinedIcon
              style={viewType === LIST_VIEW ? { color: "black" } : {}}
            />
          </StyledSocialIconButton>
          <StyledSocialIconButton onClick={() => setViewType(GRID_VIEW)}>
            <WindowOutlinedIcon
              style={viewType === GRID_VIEW ? { color: "black" } : {}}
            />
          </StyledSocialIconButton>
        </FlexRow>
      </FlexRow>
      {viewType === LIST_VIEW && (
        <StyledList
          sx={{
            marginTop: "1em",
            maxHeight: "calc(100vh - 185px)",
            padding: "0.8em",
            marginLeft: "-20px",
            width: "95%",
          }}
        >
          {connections.map((el, index) => (
            <ListItem
              key={el.id}
              sx={{
                "&::before": {
                  width: "9px",
                  content: '""',
                  height: "60px",
                  background: "white",
                  top: "50%",
                  left: 0,
                  position: "absolute",
                },
                "&::after": {
                  width: "9px",
                  content: '""',
                  height: "60px",
                  background: "white",
                  top: "50%",
                  right: 0,
                  position: "absolute",
                },
                padding: "0.6em 0.8em",
                margin: "0.6em 0 0.6em 0",
                marginTop: index === 0 ? "-0.3em" : "0.6em",
                borderBottom: `2px solid #d4d3d3`,
                borderRadius: "8px",
                background: "transparent",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  variant="circle"
                  src={el.picture}
                  style={{
                    width: "55px",
                    height: "55px",
                    marginRight: "1em",
                  }}
                />
              </ListItemAvatar>
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: "15px",
                  color: "black",
                }}
              >
                {el.userName}
              </Typography>
              {createButtonRow(el)}
            </ListItem>
          ))}
        </StyledList>
      )}
      {viewType === GRID_VIEW && (
        <StyledGrid
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{
            overflowY: "scroll",
            padding: "1em 0.8em 0 0.7em",
            maxHeight: "calc(100vh - 185px)",
            boxSizing: "border-box",
          }}
        >
          {connections.map((el, index) => (
            <Grid item style={{ margin: "0.5em" }} name={`${index}`}>
              <FlexColumn
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "150px",
                  minWidth: "150px",
                  height: "120px",
                  background: "white",
                  padding: "10px 5px",

                  boxShadow: "0.8px 0.5px 1px 1px #cdcfd4",
                }}
              >
                <Avatar
                  variant="circle"
                  src={el.picture}
                  sx={{
                    cursor: "pointer",
                    width: "62px",
                    height: "62px",
                    ".MuiAvatar-img": {
                      WebkitUserDrag: "none",
                    },
                  }}
                  onClick={() => {
                    window.open("https://" + el.data, "_blank");
                  }}
                />
                <Typography
                  style={{
                    fontWeight: "600",
                    marginTop: "5px",
                    marginBottom: "8px",
                    fontSize: "12px",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  {el.userName}
                </Typography>
                {createButtonRow(el)}
              </FlexColumn>
            </Grid>
          ))}
        </StyledGrid>
      )}
      <Drawer
        anchor={"bottom"}
        open={deleteDrawerOpen}
        onClose={() => setDeleteDrawerOpen(false)}
        PaperProps={{
          sx: {
            margin: "auto",
            width: { mobile: "100%", tablet: "580px", desktop: "800px" },
            height: "em",
          },
        }}
      >
        <FlexColumn
          style={{
            padding: "1em",
            justifyContent: "space-between",
            alignItems: "center",
            width: inputWidth,
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: "1em" }}>
            {t(prepareTranslation("Delete connection"))}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: { mobile: "300px", tablet: "fit-content" },
              marginBottom: "0.4em",
            }}
          >
            {t(
              prepareTranslation(
                "Are you sure that you want to delete connection"
              )
            ) + "?"}
            <strong>{selectedUser?.userName}</strong>
          </Typography>

          <Typography
            variant="body1"
            sx={{
              width: {
                mobile: "300px",
                tablet: "fit-content",
                fontWeight: "bold",
              },
              marginBottom: "1.8em",
            }}
          >
            {t(prepareTranslation("Attention: This action is permanent")) + "!"}
          </Typography>

          <Button
            disabled={!selectedUser}
            variant="contained"
            onClick={onConnectionDelete}
            sx={{
              ...theme.palette.deleteButton,
              width: inputWidth,
              height: "48px",
              marginBottom: "1.5em",
            }}
          >
            {t(prepareTranslation("Delete"))}
            {deleteLoading && (
              <CircularProgress
                size={"2em"}
                sx={{
                  color: "white",
                  marginLeft: "1em",
                }}
              />
            )}
          </Button>
        </FlexColumn>
      </Drawer>
    </FlexColumn>
  );
};

export default Connections;
