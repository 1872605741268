import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SaveButton from "../../components/Buttons/SaveButton";
import { FlexRow } from "../../components/sharedComponents";
import { prepareTranslation } from "../../plugins/helper";
import { StyledGrid } from "./style";
import UserManagementIllustration from "./assets/UserManagementIllustration.svg";
import { useDispatch } from "react-redux";
import {
  getLoggedUserData,
  invokeSnackMessage,
} from "../../redux/features/coreSlice";
import {
  changePasswordLoggedUser,
  changeUsernameLoggedUser,
  updateBasicsLoggedUser,
} from "../../api/apis";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const UserManagement = () => {
  const { loggedUserData } = useSelector((store) => store.core);
  const [loadingBasic, setLoadingBasic] = useState(false);
  const [loadingPass, setLoadingPass] = useState(false);
  const [loadingUsername, setLoadingUsername] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [username, setUsername] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [confirmNewUsername, setConfirmNewUsername] = useState("");

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [showPasswords, setShowPasswords] = useState({
    old: false,
    new: false,
    confirmed: false,
  });

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const onBasicDataSubmit = (e) => {
    e.preventDefault();
    setLoadingBasic(true);
    updateBasicsLoggedUser({ email, firstName, lastName })
      .then((res) => {
        dispatch(
          invokeSnackMessage({
            message: "Successfully updated basic information",
          })
        );
        dispatch(getLoggedUserData());
      })
      .finally(() => {
        setLoadingBasic(false);
      });
  };

  const onPasswordReset = (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword)
      dispatch(
        invokeSnackMessage({
          message: "Passwords don't match",
          isError: true,
        })
      );
    else {
      setLoadingPass(true);
      changePasswordLoggedUser({
        currentPassword: password,
        newPassword,
        confirmedPassword: confirmNewPassword,
      })
        .then((res) => {
          dispatch(
            invokeSnackMessage({ message: "Successful password change" })
          );
          setPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
          setShowPasswords({ old: false, new: false, confirmed: false });
        })
        .finally(() => {
          setLoadingPass(false);
        });
    }
  };

  const onUsernameChange = (e) => {
    e.preventDefault();
    if (newUsername !== confirmNewUsername)
      dispatch(
        invokeSnackMessage({
          message: "Usernames don't match",
          isError: true,
        })
      );
    else {
      setLoadingUsername(true);
      changeUsernameLoggedUser({
        newUsername: newUsername,
        confirmedUsername: confirmNewUsername,
      })
        .then((res) => {
          dispatch(
            invokeSnackMessage({ message: "Successful username change" })
          );
          dispatch(getLoggedUserData());
          setConfirmNewUsername("");
          setNewUsername("");
        })
        .finally(() => {
          setLoadingUsername(false);
        });
    }
  };
  const formStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "1em 2em",
    marginBottom: "1em",
  };

  useEffect(() => {
    if (loggedUserData) {
      setFirstName(loggedUserData?.userData?.firstName || "");
      setLastName(loggedUserData?.userData?.lastName || "");
      setEmail(loggedUserData?.userData?.email || "");
      setUsername(loggedUserData?.userData?.userName || "");
    }
  }, [loggedUserData]);

  const basicInfoMandatory = () => {
    return email?.length > 0 && firstName?.length > 0 && lastName?.length > 0;
  };

  const passwordResetMandatory = () => {
    return (
      password?.length > 0 &&
      newPassword?.length > 0 &&
      confirmNewPassword?.length > 0
    );
  };

  const usernameResetMandatory = () => {
    return (
      username?.length > 0 &&
      newUsername?.length > 0 &&
      confirmNewUsername?.length > 0
    );
  };

  const buttonWidth = "250px";

  const updateShowPasswords = (key) => {
    setShowPasswords((sPasswords) => ({
      ...sPasswords,
      [key]: !sPasswords[key],
    }));
  };

  return (
    <>
      <FlexRow
        sx={{
          display: { mobile: "none", tablet: "flex" },
          justifyContent: "center",
          alignItems: "center",
          marginTop: "-1em",
          marginBottom: "1em",
          maxHeight: "13em",
        }}
      >
        <Box
          component="img"
          src={UserManagementIllustration}
          sx={{ width: "12em", height: "12em" }}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            textDecoration: "underline",
            color: "#484747",
          }}
        >
          {t(prepareTranslation("My account"))}
        </Typography>
      </FlexRow>
      <StyledGrid container sx={{ marginTop: "-1em" }}>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <Box component="form" sx={formStyle} onSubmit={onBasicDataSubmit}>
            <Typography
              sx={{
                alignSelf: "center",
                marginBottom: "1em",
                fontSize: "19px",
                fontWeight: 500,
                color: "gray",
                fontStyle: "italic",
              }}
            >
              {t(prepareTranslation("Basic information"))}
            </Typography>
            <TextField
              size="small"
              disabled={loadingBasic}
              label={t(prepareTranslation("First name")) + "*"}
              variant="outlined"
              autoComplete="on"
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              sx={{
                marginBottom: "1em",
              }}
            />
            <TextField
              disabled={loadingBasic}
              size="small"
              label={t(prepareTranslation("Last name")) + "*"}
              variant="outlined"
              autoComplete="on"
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              sx={{
                marginBottom: "1em",
              }}
            />

            <TextField
              disabled={loadingBasic}
              size="small"
              autoComplete="on"
              label={t(prepareTranslation("Email address")) + "*"}
              variant="outlined"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              sx={{ marginBottom: "1em" }}
            />

            <SaveButton
              type="submit"
              disabled={loadingBasic || !basicInfoMandatory()}
              buttonstyle={{ alignSelf: "center" }}
              width={buttonWidth}
            />
          </Box>
        </Grid>

        <Grid mobile={12} tablet={6} desktop={4}>
          <Box component="form" sx={formStyle} onSubmit={onPasswordReset}>
            <Typography
              sx={{
                alignSelf: "center",
                marginBottom: "1em",
                fontSize: "19px",
                fontWeight: 500,
                color: "gray",
                fontStyle: "italic",
              }}
            >
              {t(prepareTranslation("Password change"))}
            </Typography>
            <TextField
              size="small"
              disabled={loadingPass}
              label={t(prepareTranslation("Current password"))}
              variant="outlined"
              autoComplete="on"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              sx={{
                marginBottom: "1em",
              }}
              type={showPasswords.old ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => updateShowPasswords("old")}
                      edge="end"
                    >
                      {showPasswords.old ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled={loadingPass}
              size="small"
              label={t(prepareTranslation("New password"))}
              variant="outlined"
              autoComplete="on"
              value={newPassword}
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
              sx={{
                marginBottom: "1em",
              }}
              type={showPasswords.new ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => updateShowPasswords("new")}
                      edge="end"
                    >
                      {showPasswords.new ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              disabled={loadingPass}
              size="small"
              autoComplete="on"
              label={t(prepareTranslation("Confirm new password"))}
              variant="outlined"
              value={confirmNewPassword}
              onChange={(event) => setConfirmNewPassword(event.target.value)}
              sx={{ marginBottom: "1em" }}
              type={showPasswords.confirmed ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => updateShowPasswords("confirmed")}
                      edge="end"
                    >
                      {showPasswords.confirmed ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <SaveButton
              type="submit"
              disabled={loadingPass || !passwordResetMandatory()}
              buttonstyle={{ alignSelf: "center" }}
              width={buttonWidth}
              buttonText="Change password"
            />
          </Box>
        </Grid>
        <Grid item mobile={12} tablet={12} desktop={4}>
          <Box component="form" sx={formStyle} onSubmit={onUsernameChange}>
            <Typography
              sx={{
                alignSelf: "center",
                marginBottom: "1em",
                fontSize: "19px",
                fontWeight: 500,
                color: "gray",
                fontStyle: "italic",
              }}
            >
              {t(prepareTranslation("Username change"))}
            </Typography>
            <TextField
              size="small"
              label={t(prepareTranslation("Current username"))}
              disabled
              variant="outlined"
              autoComplete="on"
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }}
              sx={{
                marginBottom: "1em",
              }}
            />
            <TextField
              disabled={loadingUsername}
              size="small"
              label={t(prepareTranslation("New username"))}
              variant="outlined"
              autoComplete="on"
              value={newUsername}
              onChange={(event) => {
                setNewUsername(event.target.value);
              }}
              sx={{
                marginBottom: "1em",
              }}
            />

            <TextField
              disabled={loadingUsername}
              size="small"
              autoComplete="on"
              label={t(prepareTranslation("Confirm new username"))}
              variant="outlined"
              value={confirmNewUsername}
              onChange={(event) => setConfirmNewUsername(event.target.value)}
              sx={{ marginBottom: "1em" }}
            />

            <SaveButton
              type="submit"
              disabled={loadingUsername || !usernameResetMandatory()}
              buttonstyle={{ alignSelf: "center" }}
              width={buttonWidth}
              buttonText="Change username"
            />
          </Box>
        </Grid>
      </StyledGrid>
    </>
  );
};

export default UserManagement;
