import { styled } from "@mui/system";
import { Box, MenuItem } from "@mui/material";

export const AdminLayoutContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  width: "100vw",
  boxSizing: "border-box",
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  width: "100%",
  padding: "8px 20px 8px 15px",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    color: theme.palette.primary.main,
    marginRight: "0.5em",
    width: "1.1em",
    height: "1.1em",
  },
}));

export const toolbarHeight = "70px";

export const tabsHeight = "45px";
