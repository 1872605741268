import { Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import React from "react";
import { useTranslation } from "react-i18next";
import { prepareTranslation } from "../../plugins/helper";
import { useTheme } from "@emotion/react";

const SaveButton = ({
  buttonText = "Save",
  height = "40px",
  width = "fit-content",
  variant = "contained",
  buttonstyle = {},
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Button
      {...props}
      sx={{
        width: width || "fit-content",
        height: height,

        background: theme.palette.alternate.main,
        "&:hover": {
          background: theme.palette.alternate.hover,
        },
        ...buttonstyle,
      }}
      variant={variant}
    >
      <SaveOutlinedIcon
        sx={{
          marginLeft: "-0.2em",
          marginRight: "0.2em",
          marginTop: "0.5px",
        }}
      />
      {t(prepareTranslation(buttonText))}
      {children}
    </Button>
  );
};

export default SaveButton;
