const columns = [
  { label: "Username", align: "left", id: "userName", width: "120px" },

  {
    label: "First Name",
    align: "center",
    id: "firstName",
    width: "110px",
  },
  {
    label: "Last Name",
    align: "center",
    id: "lastName",
    width: "110px",
  },
  {
    label: "Email",
    align: "center",
    id: "email",
    width: "130px",
  },
  {
    label: "Owns profile",
    align: "center",
    id: "hasProfile",
    width: "170px",
    type: "boolean",
  },
  {
    label: "Tag activated",
    align: "center",
    id: "isTagActivated",
    width: "170px",
    type: "boolean",
  },
  { label: "Tag ID", align: "center", id: "tagId", width: "120px" },
  {
    label: "Role",
    align: "center",
    id: "role",
    width: "100px",
  },
  {
    label: "User Group",
    align: "center",
    id: "name",
    nestedFrom: "userGroup",
    width: "150px",
  },
];

export default columns;
