import { styled } from "@mui/system";
import { IconButton } from "@mui/material";

export const StyledSocialIconButton = styled(IconButton)({
  width: "1.4em",
  height: "1.4em",
  margin: "0 0.1em 0 0.1em",
  "& .MuiSvgIcon-root": {
    width: "1em",
    height: "1em",
    color: "#9a9999",
  },
});

export const LayoutContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: "white",
  height: "100%",
  margin: "auto",
  [theme.breakpoints.only("mobile")]: {
    width: "100%",
  },
  [theme.breakpoints.only("tablet")]: {
    width: "80%",
  },
  [theme.breakpoints.up("desktop")]: {
    width: "80%",
  },
}));
